<template>
  <!-- 顶部导航栏组件 -->
  <div>
    <div class="nav-box" :class="['shadow']">
      <div ref="navBar" :class="['nav-bar']" :style="{ background: '#fff' }">
        <div class="container row-between">
          <div class="nav-left">
            <a href="/" class="logo">
              {{ content.title }}</a>
            <div v-for="(item, key) in content.converts" :key="key">
              <DropDown :content="item" />
            </div>
          </div>
          <div class="nav-right row">
            <a :href="content.fastLink" v-if="content.fastClick">{{ content.fastClick }}</a>
            <login-wrap-dlivewall ref="loginWrap" :content="content.loginInfo" v-if="content.showSign"/>
            <login-wrap ref="loginWrap" :content="content.loginInfo" v-else />
            
          </div>
        </div>
        <top-dialog :show="showTools">
          <div class="tools-grid">
            <div v-for="(items, index) in converts1" :key="index">
              <div class="tool-title">{{ items.title }}</div>
              <a v-for="(item, idx) in items.list" :key="idx" :href="item.path" :to="{ path: item.path }">
                <div class="tool-name">{{ item.title }}</div>
              </a>
            </div>
          </div>
        </top-dialog>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import utils from '@/common/utils'
import DropDown from './ui/DropDown.vue'
import TopDialog from './ui/TopDialog.vue'

export default {
  name: 'NavBar',
  components: { DropDown, TopDialog },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      color: '',
      // 手机端代码支持
      showMobileHeader: false,
      // end
      showTools: false,
      btnType: this.content.login,
    }
  },
  computed: {
    converts1() {
      return []
      // const convertObj = Object.fromEntries(
      //   Object.keys(this.$t('navBar').converts).slice(3, this.$t('navBar').converts.length).map(key => [key, this.$t('navBar').converts[key]])
      // )
      // return convertObj
    }
  },
  beforeMount() {
    // 支持手机端代码
    this.headerCtrl()
    window.addEventListener('resize', this.headerCtrl.bind(this))
  },
  mounted() {
    // this.setFontColor(this.topEleBg)
    // end
    window.addEventListener('click', this.handleToolClick.bind(this))
    this.$store.commit('SAVE_LOGIN_INFO', this.content.loginInfo)
  },

  methods: {
    addPoint(name) {
      // this.$api.saveOperationLog({
      //
      //   event_name,
      //   event_key: event_name,
      // })
    },
    // handleLogin() {
    //   this.$store.commit('changeDialog', { name: 'FormLayout', active: 'Login' })
    // },
    toTools(items) {
      this.$router.push({ name: items.name })
    },
    headerCtrl(e) {
      if (document.body.getBoundingClientRect().width <= 980) {
        this.showMobileHeader = true
      }
      else {
        this.showMobileHeader = false
      }
    },
    // val：navBar背景色，通过计算得出navbar文字深浅颜色
    setFontColor(val) {
      this.$nextTick(() => {
        let bg = utils.set16ToRgb(val)
        let r = 0
        if (val.match(/#\w{6,8}/)) {
          r = parseInt(bg.substr(4, 3))
        }
        this.color = 255 - r > 100 ? '#FFFFFF' : '#011E15'
      })
    },
    toolsBoxClick() {
      this.showTools = !this.showTools
    },
    handleToolClick(e) {
      if (!e.path) {
        // 不存在则遍历target节点
        let target = e.target
        e.path = []
        while (target.parentNode !== null) {
          e.path.push(target)
          target = target.parentNode
        }
        // 最后补上document和window
        e.path.push(document, window)
      }
      let converIndex = [...e.path].indexOf(this.$refs['convert-box'])
      if (!([...e.path].indexOf(this.$refs['tools-main']) === -1)) {
        this.showTools = !this.showTools
      }
      else if (converIndex >= 0 && converIndex < 5) {
        this.showTools = true
      }
      else {
        this.showTools = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$commonColor: "#419EFF"; //统一颜色

@mixin flex-around {
  display: flex;
  justify-content: space-around;
}

.shadow {
  box-shadow: 0 1px 5px 0 #b8b8b880;
  border-bottom: none;
}

@media #{'only screen and (min-width: 992px)'} {
  .placeholder {
    height: 80px;
    width: 100%;
  }

  .nav-box {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
    background-color: var(--theme-primary-white);;
    font-family: Arial, Arial;
    .nav-bar {
      position: relative;
      max-width: 1080px;
      width: 100%;
      height: 80px;
      margin: 0 auto;
      // padding: 0px 100px;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 400;
      color: var(--theme-primary-black);
      line-height: 20px;
      box-sizing: border-box;
      z-index: 2;
      transition: all 0.3s ease;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .nav-left {
          width: auto;
          @include flex-around;

          .logo {
            color: var(--theme-primary-suffix);
            font-size: 20px;
            font-weight: bold;
            white-space: nowrap;
            cursor: pointer;

            img {
              width: 100%;
            }
          }

          .item {
            padding: 10px 20px;
            user-select: none;

            a {
              cursor: pointer;
              display: block;
              text-decoration: none;
              color: var(--theme-primary-black);

              &:hover {
                span {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .nav-right {
          position: relative;
          width: auto;
          height: 100%;
          @include flex-around;
          align-items: center;

          a {
            color: #000;
            margin-right: 20px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .tools-grid {
        width: 90%;
        max-width: 1340px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 30px;
        margin: 0 auto;
        padding: 30px 0 41px;

        .tool-title {
          color: #777;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          margin-left: 12px;
          margin-bottom: 14px;
        }

        .tool-name {
          font-size: 14px;
          line-height: 46px;
          color: #05021e;
          padding-left: 12px;

          &:hover {
            background: #eef6fa;
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .placeholder {
    height: 1rem;
    width: 100%;
  }

  .nav-box {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 20;
    font-family: Arial, Arial;
    .nav-bar {
      position: relative;
      width: 100%;
      height: 1rem;
      padding: 0px .5rem;
      font-size: .3rem;
      font-weight: 400;
      color: #020b22;
      line-height: 20px;
      box-sizing: border-box;
      transition: all 0.3s ease;
      z-index: 2;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .nav-left {
          width: auto;
          @include flex-around;

          .logo {
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            text-transform: uppercase;
            // white-space: nowrap;
            color: var(--theme-primary-suffix);
            cursor: pointer;

            img {
              width: 100%;
            }
          }

          .item {
            padding: 10px 20px;
            user-select: none;

            a {
              display: block;
              text-decoration: none;
              color: #020b22;
              cursor: pointer;

              &:hover {
                span {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .nav-right {
          position: relative;
          width: auto;
          height: 100%;
          @include flex-around;
          flex-shrink: 0;
          align-items: center;

          a {
            margin-right: 10px;
            color: #000;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>