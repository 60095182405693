<template>
  <div>
    <div class="pay-result-wrap">
      <div v-if="loading" class="is-loading">
        <div class="progress-box">
          <span class="font-regular">{{ ing }}%</span>
          <div ref="progress" class="progress"></div>
        </div>
        <h2 class="font-medium">{{ content.loading.title }}</h2>
      </div>
      <div v-else class="pay-result">
        <h2 class="font-bold">{{ content[status].title }}</h2>
        <p class="font-regular" v-html="content[status].des" />
        <button class="submit-btn" @click="handleSubmit">
          {{ content[status].buttonTxt }}
        </button>
        <a href="/" class="font-bold tip" v-if="content[status].tip">
          {{ content[status].tip }}
        </a>
      </div>
    </div>
    <login-wrap ref="loginWrap" :showWrap="false" :content="site" />
  </div>
</template>

<script>
import { checkOrder } from "@/api/pay";

export default {
  name: 'Index',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      ing: 10,
      timer: null,
      status: 'success',
      loading: true,
      outTime: 60,
      currentUserInfo: {
        email: '--',
        token: ''
      },
      //  支付使用的邮箱
      payEmail: '',
      site: ''
    }
  },
  mounted() {
    this.polling(1000)
  },
  methods: {
    polling(time) {
      this.timer = setInterval(() => {
        //  超时处理
        if (this.outTime === 0) {
          clearInterval(this.timer)
          this.status = 'pending'
          this.loading = false
          return
        }
        if (this.ing < 90) {
          let random = Math.floor(Math.random() * 5) || 3
          let num = this.ing + random
          this.triggerProgress(num)
        }
        this.outTime--
        this.handleCheckOrder()
      }, time)
    },
    triggerProgress(num) {
      this.ing = num
      if (!this.$refs.progress) return
      this.$refs.progress.style.width = `${this.ing}%`
    },
    handleCheckOrder() {
      const transparentExtend = JSON.parse(this.$route.query.transparentExtend || '{}')
      const data = {
        id: transparentExtend.id,
      }
      checkOrder(data).then(result => {
        //  假如不为0，且进度条到了100（避免重复触发）说明支付成功
        if (result.obj.status !== 0 && this.ing !== 100) {
          clearInterval(this.timer)
          this.triggerProgress(100)
          this.status = 'success'
          this.loading = false
        }
      }).catch(e => {
        clearInterval(this.timer)
        this.status = 'pending'
        this.loading = false
      })
    },
    handleSubmit() {
      if (this.status === 'pending') {
        this.outTime = 30
        this.ing = 10
        this.loading = true
        this.polling(1000)
      } else {
        window.location.href = '/'
        // this.$refs.loginWrap.open()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .pay-result-wrap {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    .is-loading {
      width: auto;
      height: auto;

      .picture {
        width: 160px;
        height: 160px;
        margin: 0 auto;
      }

      .progress-box {
        width: 800px;
        height: 40px;
        background: #87a89e;
        border-radius: 35px;
        position: relative;
        margin: 40px auto;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          color: #FFFFFF;
        }

        .progress {
          position: absolute;
          width: 10%;
          height: 100%;
          top: 0;
          left: 0;
          transition: all 200ms;
          background: var(--theme-primary-suffix);
          border-radius: 35px;
          z-index: 2;
        }
      }

      h2 {
        font-size: 28px;
        line-height: 32px;
        color: #000000;
        text-align: center;
      }
    }

    .pay-result {
      width: 800px;
      text-align: center;

      h2 {
        font-size: 46px;
        line-height: 50px;
        margin-top: 40px;
        margin-bottom: 24px;
      }

      p {
        display: block;
        font-size: 24px;
        color: #2A313D;
        line-height: 24px;
        margin-top: 12px;
      }

      .highlight {
        font-weight: bold;
      }

      .link-email {
        color: #4E90F4;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .submit-btn {
    display: block;
    margin: 40px auto 0 auto;
    width: 300px;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;
    border: 1px solid #000000;
  }

  .tip {
    display: block;
    margin-top: 24px;
    font-size: 18px;
    color: #4E90F4;
    line-height: 21px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 980px) {
  .pay-result-wrap {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .is-loading {
      width: auto;
      height: auto;

      .picture {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 auto;
      }

      .progress-box {
        width: 6.86rem;
        height: 0.4rem;
        background: #87a89e;
        border-radius: 0.35rem;
        position: relative;
        margin: 0.4rem auto;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 3;
        color: #FFFFFF;
        font-size: 0.32rem;
        display: none;
      }

      .progress {
        position: absolute;
        width: 10%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all 200ms;
        background: var(--theme-primary-suffix);
        border-radius: 0.35rem;
        z-index: 2;
      }
    }

    h2 {
      font-size: 0.32rem;
      line-height: 0.32rem;
      color: #000000;
      text-align: center;
    }
  }

  .pay-result {
    width: 6.86rem;
    text-align: center;

    h2 {
      font-size: 0.48rem;
      line-height: 0.57rem;
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }

    p {
      font-size: 0.32rem;
      line-height: 0.48rem;

      &::v-deep {
        .email {
          display: block;
          font-size: 0.4rem;
          color: #2A313D;
          line-height: 0.48rem;
          margin-top: 0.32rem;
        }

        .highlight {
          font-weight: bold;
        }

        .link-email {
          color: #4E90F4;
          text-decoration: underline;
        }
      }

    }
    .submit-btn {
        display: block;
        border-radius: 4px;
        font-size: .3rem;
        border: 1px solid #000000;
        margin: .5rem auto 0;
        padding: .2rem .4rem;
      }
    .tip {
      display: block;
      margin-top: 0.32rem;
      font-size: 0.36rem;
      color: #4E90F4;
      line-height: 0.42rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
