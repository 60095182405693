const Banner = {
    "--AICartoon-banner-title-color": "white",
    "--AICartoon-banner-subtitle-color": "#b2b2b2",
  
    "--AICartoon-banner-upload-bg": "#222222",
    "--AICartoon-banner-upload-shadow": "",
  
    "--AICartoon-banner-upload-btn-color": "#fff",
    "--AICartoon-banner-upload-btn-shadow": "",
    "--AICartoon-banner-upload-btn-bg": "#FF612F",
  
    "--AICartoon-banner-upload-max-color": "#999999",
  
    "--AICartoon-banner-upload-tips-color": "#E6E6E6",
  }
  
  
  const Price = {
    "--AICartoon-price-title-color": "#252325",
  
    "--AICartoon-price-item-shadow": "none",
    "--AICartoon-price-item-shadow-hover": "none",
    "--AICartoon-price-item-border": "",
    "--AICartoon-price-item-border-hover": "",
  
    // 价格顶部
    "--AICartoon-price-tag-bg": "#E8EEF5",
    "--AICartoon-price-tag-bg-hover": "#FF612F",
    "--AICartoon-price-tag-suffix": "#101112",
    "--AICartoon-price-tag-suffix-hover": "#fff",
    // 详情模块颜色
    "--AICartoon-price-main-bg": "#E8EEF5",
    "--AICartoon-price-main-bg-hover": "#FF612F",
    // 价格颜色
    "--AICartoon-price-money-suffix": "#101012",
    "--AICartoon-price-money-suffix-hover": "#fff",
    // 套餐详情颜色
    "--AICartoon-price-desc-suffix": "#101112",
    "--AICartoon-price-desc-suffix-hover": "#fff",
    // 购买按钮
    "--AICartoon-price-pay-button-suffix": "#fff",
    "--AICartoon-price-pay-button-bg": "#FF612F",
    "--AICartoon-price-pay-button-shadow": "",
    "--AICartoon-price-pay-button-border": "",
  
    "--AICartoon-price-pay-button-suffix-hover": "#FF612F",
    "--AICartoon-price-pay-button-bg-hover": "#fff",
    "--AICartoon-price-pay-button-shadow-hover": "",
    "--AICartoon-price-pay-button-border-hover": "",
  
    // 套餐 tips
    "--AICartoon-price-tip-suffix": "#101112",
    "--AICartoon-price-tip-suffix-hover": "#fff",
    "--AICartoon-price-tip-bg": "#E8EEF5",
    "--AICartoon-price-tip-bg-hover": "#FF612F",
  }
  
  
  
  const Questions = {
    "--AICartoon-questions-title-color": "#252325",
  
    "--AICartoon-questions-item-bg": "#F2F8FF",
    "--AICartoon-questions-item-bg-open": "#FF612F",
  
    "--AICartoon-questions-item-boxshadow": "",
    "--AICartoon-questions-item-boxshadow-open": "",
  
    "--AICartoon-questions-item-border": "3px solid #FF612F",
    "--AICartoon-questions-item-border-open": "2px solid #000000",
  
    "--AICartoon-questions-item-number-suffix": "#FF612F",
    "--AICartoon-questions-item-number-suffix-open": "#000000",
  
    "--AICartoon-questions-item-suffix": "#101012",
    "--AICartoon-questions-item-suffix-open": "#fff",
    "--AICartoon-questions-item-des-suffix": '#fff',
  }
  
  const Comment = {
    "--AICartoon-comment-title-color": "#252325",
  
    "--AICartoon-comment-bg": "#F6F6F6",
  
    "--AICartoon-comment-item-bg": "#FF612F",
    "--AICartoon-comment-item-bg-hover": "#fff",
  
    "--AICartoon-comment-item-border": "none",
    "--AICartoon-comment-item-border-hover": "none",
  
    "--AICartoon-comment-item-boxshadow": "none",
    "--AICartoon-comment-item-boxshadow-hover": "none",
  
    "--AICartoon-comment-item-subtitle-suffix": "#fff",
    "--AICartoon-comment-item-subtitle-suffix-hover": "#252325",
  
    "--AICartoon-comment-item-name-suffix": "#fff",
    "--AICartoon-comment-item-name-suffix-hover": "#4C484C",
  
    "--AICartoon-comment-item-city-suffix": "#fff",
    "--AICartoon-comment-item-city-suffix-hover": "#4C484C",
  }
  
  
  const Case = {
    "--AICartoon-case-title-color": "#252325",
  
    "--AICartoon-case-bg": "#fff",
    "--AICartoon-case-item-bg": "#fff",
    "--AICartoon-case-item-bg-hover": "#FF612F",
  
    "--AICartoon-case-item-title-color": "#252325",
    "--AICartoon-case-item-title-color-hover": "#fff",
    "--AICartoon-case-item-desc-color": "#4C484C",
    "--AICartoon-case-item-desc-color-hover": "#fff",
  
    "--AICartoon-case-item-shadow": "",
    "--AICartoon-case-item-shadow-hover": "",
    "--AICartoon-case-item-border": "none",
    "--AICartoon-case-item-border-hover": "none",
  }
  
  const Step = {
    "--AICartoon-step-title-color": "#252525",
  
    "--AICartoon-step-item-title-color": "#252325",
    "--AICartoon-step-item-title-color-hover": "#FFFFFF",
    "--AICartoon-step-item-subtitle-color": "#4C484C",
    "--AICartoon-step-item-subtitle-color-hover": "#FFFFFF",
  
    "--AICartoon-step-bg": '#f2f8ff',
    "--AICartoon-step-item-bg": "#fff",
    "--AICartoon-step-item-bg-hover": "#FF612F",
    "--AICartoon-step-item-shadow": "0px 10px 30px 0px rgba(0,0,0,0.12)",
    "--AICartoon-step-item-shadow-hover": "",
    "--AICartoon-step-item-border": "none",
    "--AICartoon-step-item-border-hover": "none",
  }
  
  
  export const AICartoonOrange = {
    ...Banner,
    ...Price,
    ...Questions,
    ...Comment,
    ...Case,
    ...Step
  }