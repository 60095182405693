<template>
    <div class="koutu-upload">
        <div class="wrap">
            <div class="title">{{ content.title }}</div>
            <div class="content" v-loading="loading">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <el-upload class="pic-uploader"
                       :action="''"          
                       :auto-upload="false"    
                       :show-file-list="false"   
                       :on-change="handleImgChange">
                
                <div class="uploadBtn" v-if="!showDownload"><i class="el-icon-plus"></i><span>{{ content.btnText }}</span></div>
                
                </el-upload>
                <div style="text-align: center;" v-if="showDownload">
                  <div class="uploadBtn" @click="download"><span>Save image</span></div>
                </div>
                
                <ul>
                    <li v-for="line in content.desList" :key="line">
                        {{ line }}
                    </li>
                    <li>
                       After successful payment, upload and save images on this page.
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  import { createOrder, payOrder } from '@/api/pay'
  export default {
    name: 'Koutu_Upload',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
    data() {
      return {
        imageUrl: '',
        showDownload: false,
        loading: false
      }
    },
    mounted() {
      if(this.$route.query.paySuccess){
        this.showDownload = true;
        if (localStorage.getItem('koutuImg')) {
            this.imageUrl = localStorage.getItem('koutuImg');
          }
      }
    },
    methods: {
  
  
      async handlePay() {
        try {
          const order = await createOrder({
            productsId: '1742513321016782850',
            productsPriceId: '0',
            successUrl: `${this.$origin('upload?paySuccess=1')}`, //  支付成功url
            cancelUrl: `${this.$origin('payresult')}`, //  取消支付链接
            userExtend: {},
            transparentExtend: {},
            orderAdInfoExtend: {},
            marketingChannel: 'seo'
          })
  
          const result = await payOrder({
            orderId: order.obj.orderId,
            payExtend: {}
          })
          // 存储支付链接
          this.$store.commit("SAVE_PAY_URL", result.obj.redirectUrl);
          // 存储点击的价格
          this.$store.commit("SAVE_PAY_PRICE", '19.99');
          window.location.href = '/payout/paying'
          // window.location.href = result.obj.redirectUrl
        } catch (e) {
          this.loading = false
          alert(e.msg || e)
        }
      },
      
      handleImgChange(file) {//选中文件触发的change事件
    
        const isJPG = file.raw.type === 'image/jpeg'
        const isPNG = file.raw.type === 'image/png'
        // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
        if (!isPNG && !isJPG) {
          this.$message.error('Upload images can only be in JPG/PNG format!')
          return false
        } else {
          this.imageUrl = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
          // console.log(this.imageUrl);
          this.uploadImg(file);//调用上传文件api接口
  
        }
      },
      // 文件上传操作
      uploadImg(file){
        this.loading = true;
        let formData=new FormData();
        formData.append('image',file.raw);
  
        // formData
        this.$api.koutuUploadImg(formData).then((res) => {
          // console.log(res);
            if(res.code === 2000){
              this.imageUrl = res.result;
              this.showDownload = true;
              localStorage.setItem('koutuImg', this.imageUrl);
              this.loading = false;
            }else{
              this.loading = false;
              this.$message.error('Free convert 1 time!')
            }
          }).catch((err) => {
            console.log(err, '失败 >>>')
          });
        },
      // 下载图片地址和图片名
      downloadIamge(imgsrc, name) {
        var image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      
          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
      },
      download(){
        // 成功则下载
        if(this.$route.query.paySuccess){
          this.downloadIamge(this.imageUrl, 'pic');
        }else{
          this.handlePay();
        }
      }
  
    }
  }
  </script>
  
  <style scoped lang="scss">
  @media #{'only screen and (min-width: 992px)'} {
    .koutu-upload {
      background: var(--theme-primary-koutu-upload-bg);
      font-family: Arial, Arial;
      .wrap {
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0 100px;
        .title {
            font-size: 60px;
            font-weight: 900;
            color: var(--theme-primary-black);
            line-height: 1.3;
            text-align: center;
            width: 100%;
            margin: 0 auto 40px;
        }
        .content {
            background-color: white;
            padding: 50px 100px;
            border-radius: 36px;
            ul{
                li{
                    font-size: 20px;
                    line-height: 2;
                    color: #4C484C;
                    &::marker{
                        color: #D9D9D9;
                    }
                    &:last-child {
                      color: red;
                    }
                }
            }
            .avatar{
                margin: 0 auto;
                display: block;
                padding: 20px 0;
                max-width: 50%;
            }
            .uploadBtn {
                  margin: 20px 0 50px;
                  padding: 20px 50px;
                  font-size: 26px;
                  display: inline-block;
                  color: white;
                  font-weight: bold;
                  cursor: pointer;
                  box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
                  background: var(--theme-primary);
                  border-radius: 40px;
                  span {
                      margin-left: 10px;
                  }
              }
            .pic-uploader {
                text-align: center;
            }
  
        }
      }
    }
  }
  @media #{'only screen and (max-width: 991px)'} {
    .koutu-upload {
      background: var(--theme-primary-koutu-upload-bg);
      font-family: Arial, Arial;
      .wrap {
        width: 100%;
        margin: 0 auto;
        padding: 1rem 0;
        .title {
            font-size: .48rem;
            font-weight: 900;
            color: var(--theme-primary-black);
            line-height: 1.3;
            text-align: center;
            width: 100%;
            margin: 0 auto 1rem;
        }
        .content {
            background-color: white;
            border-radius: .5rem;
            padding: .5rem 0;
            margin: 0 .3rem;
            ul{
                padding: 0 .6rem;
                li{
                    font-size: .3rem;
                    line-height: 2;
                    color: #4C484C;
                    &::marker{
                        color: #D9D9D9;
                    }
                    &:last-child {
                      color: red;
                    }
                }
            }
            .avatar{
                margin: 0 auto;
                display: block;
                padding: .5rem 0;
                max-width: 80%;
            }
            .uploadBtn {
                    padding: .25rem 1rem;
                    font-size: .3rem;
                    margin: .5rem 0;
                    display: inline-block;
                    cursor: pointer;
                    color: white;
                    font-weight: bold;
                    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
                    background: var(--theme-primary);
                    border-radius: .8rem;
                    span {
                        margin-left: .2rem;
                    }
                }
            .pic-uploader {
                text-align: center;
            }
  
        }
      }
    }
  }
  </style>