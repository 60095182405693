const Banner = {
  "--AICartoon-banner-title-color": "#FFFFFF",
  "--AICartoon-banner-subtitle-color": "#fff",

  "--AICartoon-banner-upload-bg": "#034EF3",
  "--AICartoon-banner-upload-shadow": "",

  "--AICartoon-banner-upload-btn-color": "#FFFFFF",
  "--AICartoon-banner-upload-btn-shadow": "",
  "--AICartoon-banner-upload-btn-bg": "#FF9B52",

  "--AICartoon-banner-upload-max-color": "rgba(255,255,255,0.7)",

  "--AICartoon-banner-upload-tips-color": "rgba(255,255,255,0.7)",
}

const Price = {
  "--AICartoon-price-title-color": "#252325",

  "--AICartoon-price-item-shadow": "none",
  "--AICartoon-price-item-shadow-hover": "none",
  "--AICartoon-price-item-border": "1px solid #FF9B52",
  "--AICartoon-price-item-border-hover": "1px solid #FF9B52",

  // 价格顶部
  "--AICartoon-price-tag-bg": "#fff",
  "--AICartoon-price-tag-bg-hover": "#fff",
  "--AICartoon-price-tag-suffix": "#252325",
  "--AICartoon-price-tag-suffix-hover": "#252325",
  // 详情模块颜色
  "--AICartoon-price-main-bg": "#fff",
  "--AICartoon-price-main-bg-hover": "#fff",
  // 价格颜色
  "--AICartoon-price-money-suffix": "#FF9B52",
  "--AICartoon-price-money-suffix-hover": "#FF9B52",
  // 套餐详情颜色
  "--AICartoon-price-desc-suffix": "#101012",
  "--AICartoon-price-desc-suffix-hover": "#101012",
  // 购买按钮
  "--AICartoon-price-pay-button-suffix": "#FFF",
  "--AICartoon-price-pay-button-suffix-hover": "#FFF",
  "--AICartoon-price-pay-button-bg": "#FF8C19",
  "--AICartoon-price-pay-button-shadow": "none",
  "--AICartoon-price-pay-button-bg-hover": "#FF8C19",
  // 套餐 tips
  "--AICartoon-price-tip-suffix": "#252325",
  "--AICartoon-price-tip-suffix-hover": "#252325",
  "--AICartoon-price-tip-bg": "#EDEDED",
  "--AICartoon-price-tip-bg-hover": "#EDEDED",
}

const Questions = {
  "--AICartoon-questions-title-color": "#252325",

  "--AICartoon-questions-item-bg": "#F2F4F3",
  "--AICartoon-questions-item-bg-open": "#0270F9",

  "--AICartoon-questions-item-boxshadow": "none",
  "--AICartoon-questions-item-boxshadow-open": "0px 20px 50px 0px rgba(0,0,0,0.15)",

  "--AICartoon-questions-item-border": "none",
  "--AICartoon-questions-item-border-open": "none",

  "--AICartoon-questions-item-number-suffix": "#0270F9",
  "--AICartoon-questions-item-number-suffix-open": "#fff",

  "--AICartoon-questions-item-suffix": "#101012",
  "--AICartoon-questions-item-suffix-open": "#fff",
  "--AICartoon-questions-item-des-suffix": '#fff',
}

const Comment = {
  "--AICartoon-comment-title-color": "#252325",

  "--AICartoon-comment-bg": "#fff",

  "--AICartoon-comment-item-bg": "#F2F4F3",
  "--AICartoon-comment-item-bg-hover": "#0270F9",

  "--AICartoon-comment-item-border": "none",
  "--AICartoon-comment-item-border-hover": "none",

  "--AICartoon-comment-item-boxshadow": "none",
  "--AICartoon-comment-item-boxshadow-hover": "none",

  "--AICartoon-comment-item-subtitle-suffix": "#252325",
  "--AICartoon-comment-item-subtitle-suffix-hover": "#fff",

  "--AICartoon-comment-item-name-suffix": "#4C484C",
  "--AICartoon-comment-item-name-suffix-hover": "#fff",

  "--AICartoon-comment-item-city-suffix": "#999199",
  "--AICartoon-comment-item-city-suffix-hover": "#fff",
}

const Case = {
  "--AICartoon-case-title-color": "#252325",

  "--AICartoon-case-bg": "#fff",
  "--AICartoon-case-item-bg": "#fff",
  "--AICartoon-case-item-bg-hover": "#fff",

  "--AICartoon-case-item-title-color": "#252525",
  "--AICartoon-case-item-title-color-hover": "#252525",
  "--AICartoon-case-item-desc-color": "#4C484C",
  "--AICartoon-case-item-desc-color-hover": "#4C484C",

  "--AICartoon-case-item-shadow": "",
  "--AICartoon-case-item-shadow-hover": "",
  "--AICartoon-case-item-border": "none",
  "--AICartoon-case-item-border-hover": "none",
}

const Step = {
  "--AICartoon-step-title-color": "#fff",

  "--AICartoon-step-item-title-color": "#0270F9",
  "--AICartoon-step-item-title-color-hover": "#0270F9",

  "--AICartoon-step-bg": '#0270F9',
  "--AICartoon-step-item-bg": "#fff",
  "--AICartoon-step-item-bg-hover": "#fff",
  "--AICartoon-step-item-shadow": "",
  "--AICartoon-step-item-shadow-hover": "",
  "--AICartoon-step-item-border": "none",
  "--AICartoon-step-item-border-hover": "none",
}


export const coustomAICartoonBrandeisBlueTheme = {
  //主题背景颜色
  "--theme-primary": "#0270F9",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#0270F9",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "#fff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#212121",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...Banner,
  ...Price,
  ...Questions,
  ...Comment,
  ...Case,
  ...Step
}