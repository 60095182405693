<template>
  <div>
    <NavBar :content="site.NavBar.props.content" v-if="site.NavBar.props.content" />
    <router-view></router-view>
    <FooterBar :content="site.FooterBar.props.content" v-if="site.NavBar.props.content" />
  </div>
</template>

<script>
import { VUE_APP_SITE_ENTRANCE } from './utils/config';

export default {
  data() {
    return {
      site: {
        NavBar: { props: {} },
        FooterBar: { props: {} },
      },
    };
  },
  mounted() {
    if(process.env.NODE_ENV == 'development') {
      sessionStorage.setItem("SiteEntrance", VUE_APP_SITE_ENTRANCE); // 修改站点入口
    }
    this.init();
  },
  methods: {
    async init() {
      let host = this.$extractDomain(window.location.hostname);
      if (host == "localhost") host = sessionStorage.getItem("SiteEntrance");
      // if(!host){
      //   host = '3dlivewall2022'
      // }
      console.log("🚀  host: >>>", host);
      let result = await this.$api.getSiteInfo({ host });
      console.log(result,"result>>>")
      const themeJson = require("./theme/default.js");
      const theme = themeJson.theme[`${result.data.site.theme || 'default'}Theme`];
      if (theme) {
        var root = document.querySelector(":root");
        Object.keys(theme).forEach((key) => {
          root.style.setProperty(key, theme[key]);
        });
      }
      if (!sessionStorage.getItem("DefaultInfo")) {
        sessionStorage.setItem("DefaultInfo", JSON.stringify(result.data.common));
        this.site = JSON.parse(sessionStorage.getItem("DefaultInfo"))
        console.log("🚀  this.site: >>>", this.site);
      } else {
        this.site = JSON.parse(sessionStorage.getItem("DefaultInfo"))
      }
      sessionStorage.setItem("funType", result.data.type);
      let html = document.getElementsByTagName("html")[0];
      let width = window.innerWidth < 980 ? window.innerWidth : 1200;
      /* 640 100  320 50 */
      let fontSize = (width / 750) * 100;
      if (window.innerWidth > 980) {
        html.style.fontSize = 14 + "px";
      } else {
        html.style.fontSize = fontSize + "px";
      }

      window.innerObj = {
        innerWidth: window.innerWidth,
      };
      window.onresize = function () {
        let html = document.getElementsByTagName("html")[0];
        let width = window.innerWidth < 980 ? window.innerWidth : 980;
        window.innerObj.innerWidth = window.innerWidth;
        /* 640 100  320 50 */
        let fontSize = (width / 750) * 100;
        if (window.innerWidth > 980) {
          html.style.fontSize = 14 + "px";
        } else {
          html.style.fontSize = fontSize + "px";
        }
      };
    },
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.maks {
  height: 100vh;
}

:root {
  /* 主题背景颜色 */
  --theme-primary: linear-gradient(315deg, #68c1ff 0%, #1681fd 100%);
  /* 默认黑 */
  --theme-primary-black: #000000;
  /* 默认白 */
  --theme-primary-white: #ffffff;
  /* 主题亮色 */
  --theme-primary-suffix: #1681fd;
  /* 价格 tag */
  --theme-primary-price-tag: #3790f7;
  --theme-primary-price-tag-bg: #e9edf8;
  /* 标签 */
  --theme-label: linear-gradient(115deg, #ff7373 0%, #fd9a5e 100%);
  /* tiny 类 */
  --theme-primary-tiny: #7c7c7c;
  /* footer 背景色 */
  --theme-primary-footer-bg: #1681fd;

  /* 输入框聚焦 */
  --theme-focus: #419eff;
  /* 输入框错误 */
  --theme-focus-err: #ea3042;

  /* banner 容器 */
  // --theme-primary-banner-title: #000000;
  // --theme-primary-banner-des1: #000029;
  /* explain 容器 */
  // --theme-primary-explain-title: #000000;
  // --theme-primary-explain-des: #000000;
  // --theme-primary-explain-des1: #000029;
  /* whyChoose 容器 */
  // --theme-primary-whyChoose-title: #ffffff;
  // --theme-primary-whyChoose-des: #ffffff;
  // --theme-primary-whyChoose-explain: #000000;
  // --theme-primary-whyChoose-exclusive: #ffffff;
  /* price 容器 */
  // --theme-primary-price-title: #000000;
  // --theme-primary-price-title-suffix: #ffffff;
  // --theme-primary-price-des: #000029;
  // --theme-primary-price-explain: #000000;
  // --theme-primary-price-exclusive: #ffffff;
  // --theme-primary-price-tiny: #7c7c7c;
  // --theme-primary-price-tag: #76c225;
  /* question 容器 */
  // --theme-primary-question-title: #000000;
  // --theme-primary-question-des: #000000;
  // --theme-primary-question-tiny: #7c7c7c;
  /* footer 容器 */
  // --theme-primary-footer-title: #ffffff;
  // --theme-primary-footer-des: #ffffff;
  // --theme-primary-footer-a: #ffffff;
  // --theme-primary-footer-tiny: #7c7c7c;
  // --theme-primary-footer-bg: #329678;
  /* 通用类 */
  // --theme-font-normal: #000000;
  // --theme-font-normal-bg: #ffffff;
  // --theme-font-normal-suffix: #ffffff;
}
</style>
