<template>
    <div class="koutu-pay">
        <div class="wrap" @click="iframeClick">
          <iframe id="iframe" :src="PayUrl" frameborder="no"></iframe>
          <!-- @load="iframeClick" -->
          <div class="clickCover" v-show="ifShowCover"></div>
        </div>
        <div class="desc">
          
          <div class="text"><el-checkbox v-model="checked"></el-checkbox> Starting today, we'll charge you AU${{ PayPrice }} every {{ date }}. You'll be notified before any price changes. Cancel within 30 days after your first charge to receive a refund. Turn off recurring billing to stop future charges by visiting your account at least 3 days before the next billing date. <span class="link" @click="toTerm">Learn more.</span></div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_pay',
    data() {
      return {
        PayUrl: '',
        checked: false,
        PayPrice: '',
        date: 'month',
        loading: true
      }
    },
    mounted() {
      if (localStorage.getItem('payUrl')) {
        this.PayUrl = localStorage.getItem('payUrl');
        this.PayPrice = localStorage.getItem('payPrice');
        if(this.PayPrice === '99.99') this.date = 'year';

      }
      // console.log(this.PayUrl);
    },
    computed: {
      ifShowCover() {
        return this.checked === true ? false : true;
      }
    },
    methods: {
      iframeClick() {
        if(this.checked === false){
          this.$notify({
            title: 'Confirmation',
            message: 'Please confirm the subscription terms at the bottom',
            type: 'success'
          });
        }
      },
      toTerm(){
        window.location.href = '/sub/termsconditions'
      }
    }

  }
  </script>
  
  <style scoped lang="scss">

  @media #{'only screen and (min-width: 992px)'} {
    iframe {
      width: 1000px; 
      height: 698px;
      margin: 10px 0 0;
    }
    .koutu-pay {
      background: white;
      font-family: Arial, Arial;
      .wrap {
        width: 1000px;
        position:relative;
        margin: 0 auto;
        .clickCover{
          position: absolute;
          z-index: 5;
          // background: red;
          background-color: transparent;
          margin: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: opacity .3s;
        }
      }
      .desc{
        width: 580px;
        margin: 0 auto 50px;
        color: black;
        .text{
          color: #7b7a7a;
          font-size: 14px;
        }
        .link{
          color: #2d7af6;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  @media #{'only screen and (max-width: 991px)'} {
    iframe {
      width: 350px; 
      height: 430px;
      margin: 10px 0 0;
    }
    .koutu-pay {
      background: white;
      font-family: Arial, Arial;
      .wrap {
        width: 350px;
        padding: .5rem 0 0;
        position:relative;
        margin: 0 auto;
        .clickCover{
          position: absolute;
          z-index: 5;
          background-color: transparent;
          margin: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: opacity .3s;
        }
      }
      .desc{
        width: 90%;
        margin: 0 auto .5rem;
        color: black;
        .text{
          color: #7b7a7a;
          font-size: .25rem;
        }
        .link{
          color: #2d7af6;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  </style>