<template>
 <div class="wrap">
  <div class="explain">
      <h2 class="title">{{ content.title }}</h2>
      <div class="explain-list">
        <template v-for="(item, idx) in content.list">
          <div :key="idx" class="explain-item">
            <div class="item-left">
              <h3 class="mini-title" :class="{'highLight': content.highLightTitle}">{{ item.title }}</h3>
              <p class="des">{{ item.des }}</p>
            </div>
            <img :src="$getFile(item.img)" v-if="item.img" alt="">
          </div>
        </template>
      </div>
    </div>
 </div>

  </template>
  
  <script>
  export default {
    name: 'AIChat_Explain',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
    data() {
      return {}
    }
  }
  </script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
  .wrap {
    background: var(--theme-AIChat-explain-bg);
    .explain {
      width: 1080px;
      margin: 0 auto;
      padding: 120px 0;
      text-align: center;
      cursor: default;
      font-family: Arial, Arial;
      .title {
        margin-bottom: 20px;
        font-size: 60px;
        font-weight: 900;
        width: 90%;
        line-height: 1.3;
        margin: 0 auto;
        color: var(--theme-primary-black);
      }
  
      .des {
        font-size: 20px;
        font-weight: 400;
        color: var(--theme-primary-tiny);
        line-height: 1.3;
      }
  
      .explain-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        margin-top: 60px;
        text-align: left;
  
        .explain-item {
          display: flex;
          align-items: center;
          padding: 20px;
          justify-content: space-between;
          border-radius: 24px;
          box-shadow: var(--theme-AIChat-explain-item-shadow);
          background: var(--theme-AIChat-explain-inner-bg);
          // :nth-child(2n) 选择偶数
          &:nth-child(2n-1) {
            flex-direction: row-reverse;
          }
          .item-left {
            padding: 0 40px;
            .mini-title {
              margin-bottom: 20px;
              font-size: 30px;
              font-weight: bold;
              color: var(--theme-primary-black);
              line-height: 1.3;
              &.highLight{
                  color: var(--theme-primary-suffix);
                }
            }
          }
          img {
              width: 460px;
              height: 300px;
              flex: 0 0 auto;
            }
          }
        }
      }
    }
  }
  @media #{'only screen and (max-width: 991px)'} {
    .wrap {
      background: var(--theme-AIChat-explain-bg);
      .explain {
        width: 6.86rem;
        margin: 0 auto;
        padding: 1rem 0;
        text-align: center;
        font-family: Arial, Arial;
        .title {
          margin-bottom: .14rem;
          font-size: .48rem;
          color: var(--theme-primary-black);
          line-height: .72rem;
        }
    
        .des {
          font-size: .28rem;
          font-weight: 400;
          color: var(--theme-primary-black);
          line-height: .43rem;
        }
    
        .explain-list {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 0.8rem;
          margin-top: .8rem;
          text-align: left;
    
          .explain-item {
            display: flex;
            flex-direction: column-reverse;

            border-radius: .5rem;
            background: var(--theme-AIChat-explain-inner-bg);
            .item-left {
              padding: 0 .4rem .5rem .4rem;
              .mini-title {
                margin-bottom: .16rem;
                font-size: .32rem;
                color: var(--theme-primary-black);
                line-height: .48rem;
                &.highLight{
                    color: var(--theme-primary-suffix);
                  }
              }
            }
    
            img {
              display: block;
              width: 6.68rem;
              height: 4.57rem;
              margin: 0 auto .41rem auto;
            }
          }
        }
      }
    }
  }
  </style>