export const AIChatOrange_SynthChat = {
    // 模块配置
    // AIChat - banner
    "--theme-AIChat-banner-title-color": "#4C3626",
    "--theme-AIChat-banner-des-color": "#111212",
    "--theme-AIChat-banner-btn-color": "white",
    "--theme-AIChat-banner-btn-bg": "#FF612F",
    // AIChat - explain
    "--theme-AIChat-explain-bg": "white",
    "--theme-AIChat-explain-inner-bg": "white",
    "--theme-AIChat-explain-item-shadow": "none",
    "--theme-AIChat-explain-inner-bg-hover": "#17A18A",
    // AIChat - step
    "--theme-AIChat-step-bg": "#F6F6F6",
    "--theme-AIChat-step-inner-bg": "white",
    "--theme-AIChat-step-inner-bg-hover": "#4C3626",
    "--theme-AIChat-step-title-color": "#111212",
    "--theme-AIChat-step-inner-title-color": "#252325",
    "--theme-AIChat-step-inner-desc-color": "#252325",
    "--theme-AIChat-step-inner-title-color-hover": "#FF612F",
    "--theme-AIChat-step-inner-desc-color-hover": "white",
    "--theme-AIChat-step-radius": "0px",
    // AIChat - price
    "--theme-AIChat-price-btn-bg": "#FF612F",
    "--theme-AIChat-price-btn-bg-hover": "#FF612F",
    "--theme-AIChat-price-btn-color": "white",
    "--theme-AIChat-price-btn-color-hover": "white",
    "--theme-AIChat-price-text-color": "#FF612F",
    "--theme-AIChat-price-text-color-hover": "white",
    "--theme-AIChat-price-ul-text-align": "center",
    "--theme-AIChat-price-item-bg": "#F2F2F2",
    "--theme-AIChat-price-item-bg-hover": "#4C3626",
    "--theme-AIChat-price-item-border-radius": "0px",
    "--theme-AIChat-price-light": "#705E51",
    "--theme-AIChat-price-deep": "#705E51",
    // AIChat - question
    "--theme-AIChat-question-bg": "#F6F6F6",
    // AIChat - comment
    "--theme-AIChat-comment-border-radius": "0px",
    "--theme-AIChat-comment-item-bg-hover": "#4C3626",
   
   }