const Banner = {
  "--AICartoon-banner-title-color": "#FFFFFF",
  "--AICartoon-banner-subtitle-color": "#FFFFFF",

  "--AICartoon-banner-upload-bg": "#4501DE",
  "--AICartoon-banner-upload-shadow": "8px solid #4E03F7",

  "--AICartoon-banner-upload-btn-color": "#fff",
  "--AICartoon-banner-upload-btn-shadow": "",
  "--AICartoon-banner-upload-btn-bg": "#FF8C19",

  "--AICartoon-banner-upload-max-color": "#808080",

  "--AICartoon-banner-upload-tips-color": "#B3B3B3",
}


const Price = {
  "--AICartoon-price-title-color": "#7942FE",

  "--AICartoon-price-item-shadow": "none",
  "--AICartoon-price-item-shadow-hover": "none",
  "--AICartoon-price-item-border": "none",
  "--AICartoon-price-item-border-hover": "none",

  // 价格顶部
  "--AICartoon-price-tag-bg": "#F5F7F6",
  "--AICartoon-price-tag-bg-hover": "#F5F7F6",
  "--AICartoon-price-tag-suffix": "#252325",
  // 详情模块颜色
  "--AICartoon-price-main-bg": "#F5F7F6",
  "--AICartoon-price-main-bg-hover": "#F5F7F6",
  // 价格颜色
  "--AICartoon-price-money-suffix": "#FF8D1A",
  "--AICartoon-price-money-suffix-hover": "#FF8D1A",
  // 套餐详情颜色
  "--AICartoon-price-desc-suffix": "#101012",
  "--AICartoon-price-desc-suffix-hover": "#101012",
  // 购买按钮
  "--AICartoon-price-pay-button-suffix": "#FFF",
  "--AICartoon-price-pay-button-bg": "#FF8C19",
  "--AICartoon-price-pay-button-shadow": "none",
  "--AICartoon-price-pay-button-bg-hover": "#FF8C19",
  // 套餐 tips
  "--AICartoon-price-tip-suffix": "#252325",
  "--AICartoon-price-tip-bg": "#F5F7F6",
  "--AICartoon-price-tip-suffix-hover": "#252325",
  "--AICartoon-price-tip-bg-hover": "#F5F7F6",
}

const Questions = {
  "--AICartoon-questions-title-color": "#7942FE",

  "--AICartoon-questions-item-bg": "#F2F4F3",
  "--AICartoon-questions-item-bg-open": "#7942FE",

  "--AICartoon-questions-item-boxshadow": "none",
  "--AICartoon-questions-item-boxshadow-open": "none",

  "--AICartoon-questions-item-border": "none",
  "--AICartoon-questions-item-border-open": "none",

  "--AICartoon-questions-item-number-suffix": "#FF6B30",

  "--AICartoon-questions-item-suffix": "#101012",
  "--AICartoon-questions-item-suffix-open": "#fff",

  "--AICartoon-questions-item-des-suffix": '#fff',
}

const Comment = {
  "--AICartoon-comment-title-color": "#7942FE",

  "--AICartoon-comment-bg": "#fff",

  "--AICartoon-comment-item-bg": "#F2F4F3",
  "--AICartoon-comment-item-bg-hover": "#FFE000",

  "--AICartoon-comment-item-border": "none",
  "--AICartoon-comment-item-border-hover": "none",

  "--AICartoon-comment-item-boxshadow": "none",
  "--AICartoon-comment-item-boxshadow-hover": "none",

  "--AICartoon-comment-item-subtitle-suffix": "#252325",
  "--AICartoon-comment-item-subtitle-suffix-hover": "#fff",

  "--AICartoon-comment-item-name-suffix": "#4C484C",
  "--AICartoon-comment-item-name-suffix-hover": "#fff",

  "--AICartoon-comment-item-city-suffix": "#999199",
  "--AICartoon-comment-item-city-suffix-hover": "#fff",
}

const Case = {
  "--AICartoon-case-title-color": "#7942FE",

  "--AICartoon-case-bg": "#fff",
  "--AICartoon-case-item-bg": "#fff",
  "--AICartoon-case-item-bg-hover": "#7942FE",
  "--AICartoon-case-item-title-color": "#252525",
  "--AICartoon-case-item-title-color-hover": "#FAF9FC",
  "--AICartoon-case-item-desc-color": "#4C484C",
  "--AICartoon-case-item-desc-color-hover": "#FAF9FC",

  "--AICartoon-case-item-shadow": "none",
  "--AICartoon-case-item-shadow-hover": "none",
  "--AICartoon-case-item-border": "none",
  "--AICartoon-case-item-border-hover": "none",
}

const Step = {
  "--AICartoon-step-title-color": "#FFFFFF",

  "--AICartoon-step-bg": 'linear-gradient(to right, #414DF7 0%, #863DFE 100%)',
  "--AICartoon-step-item-bg": "#fff",
  "--AICartoon-step-item-bg-hover": "#fff",
  "--AICartoon-step-item-shadow": "none",
  "--AICartoon-step-item-shadow-hover": "none",
  "--AICartoon-step-item-border": "none",
  "--AICartoon-step-item-border-hover": "none",
}


export const AICartoonBigViolet = {
  ...Banner,
  ...Price,
  ...Questions,
  ...Comment,
  ...Case,
  ...Step
}