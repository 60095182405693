export const AIChatBlue = {
    // 模块配置
 // AIChat - banner
 "--theme-AIChat-banner-title-color": "white",
 "--theme-AIChat-banner-des-color": "white",
 "--theme-AIChat-banner-btn-color": "white",
 "--theme-AIChat-banner-btn-bg": "#FF9B52",
 // AIChat - explain
 "--theme-AIChat-explain-bg": "white",
 "--theme-AIChat-explain-inner-bg": "#F2F2F2",
 "--theme-AIChat-explain-item-shadow": "none",
 "--theme-AIChat-explain-inner-bg-hover": "#17A18A",
 // AIChat - step
 "--theme-AIChat-step-bg": "#0270F9",
 "--theme-AIChat-step-inner-bg": "white",
 "--theme-AIChat-step-inner-bg-hover": "#034EF3",
 "--theme-AIChat-step-title-color": "white",
 "--theme-AIChat-step-inner-title-color": "#0270F9",
 "--theme-AIChat-step-inner-desc-color": "#252325",
 "--theme-AIChat-step-inner-title-color-hover": "white",
 "--theme-AIChat-step-inner-desc-color-hover": "white",
 "--theme-AIChat-step-radius": "10px",
 // AIChat - price
 "--theme-AIChat-price-btn-bg": "#FF9B52",
 "--theme-AIChat-price-btn-color": "#252325",
 "--theme-AIChat-price-btn-color-hover": "#252325",
 "--theme-AIChat-price-btn-bg-hover": "#FF9B52",
 "--theme-AIChat-price-text-color": "#FF9B52",
 "--theme-AIChat-price-text-color-hover": "#FF9B52",
 "--theme-AIChat-price-ul-text-align": "left",
 "--theme-AIChat-price-item-bg": "white",
 "--theme-AIChat-price-item-bg-hover": "#0270F9",
 "--theme-AIChat-price-item-border-radius": "10px",
 "--theme-AIChat-price-light": "rgba(3, 78, 243, 0.2)",
 "--theme-AIChat-price-deep": "#034EF3",
 // AIChat - question
 "--theme-AIChat-question-bg": "#F6F6F6",
 // AIChat - comment
 "--theme-AIChat-comment-border-radius": "20px",
 "--theme-AIChat-comment-item-bg-hover": "#0270F9",

}