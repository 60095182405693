<template>
    <!-- 首页banner 组件 -->
    <!-- :class="[{'ChatCraft-AI-bg': content.ChatCraftAI},{'ChatCraft-AI-bg-2': content.ChatCraftAI2}]" -->
    <div class="banner-container " :class="[{'ChatCraft-AI-bg': content.ChatCraftAI},{'TalkBuddy-Pro-bg': content.TalkBuddyPro},{'SynthChat-bg': content.SynthChat},{'IntelliTalk-bg': content.IntelliTalk},{'SmartChats-bg': content.SmartChats},{'SolvoChat-bg': content.SolvoChat},{'ResponseRift-bg': content.ResponseRift},{'QuesTech-bg': content.QuesTech},{'QueryMate-bg': content.QueryMate},{'Insightify-bg': content.Insightify}]">
      <div class="banner-box" :class="[{'rowReverse': content.rowReverse}]">
        <div class="banner-left">
          <h1 class="title">{{ content.title }}</h1>
          <p class="des">{{ content.des }}</p>
          <a :href="content.link"> 
            <div class="btn">{{ content.btnText }}</div>
          </a>
        </div>
        <div class="banner-right">
          <img :src="$getFile(content.img)" v-if="content.img" alt="">
 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AIChat_Banner',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      &.ChatCraft-AI-bg {
        background: url("@/assets/images/AIchat/Banner/ChatCraft-AI/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.TalkBuddy-Pro-bg{
        background: url("@/assets/images/AIchat/Banner/TalkBuddy-Pro/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.SynthChat-bg{
        background: url("@/assets/images/AIchat/Banner/SynthChat/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.IntelliTalk-bg{
        background: url("@/assets/images/AIchat/Banner/IntelliTalk/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.SmartChats-bg{
        background: url("@/assets/images/AIchat/Banner/SmartChats/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.SolvoChat-bg{
        background: url("@/assets/images/AIchat/Banner/SolvoChat/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ResponseRift-bg{
        background: url("@/assets/images/AIchat/Banner/ResponseRift/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.QuesTech-bg{
        background: url("@/assets/images/AIchat/Banner/QuesTech/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.QueryMate-bg{
        background: url("@/assets/images/AIchat/Banner/QueryMate/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.Insightify-bg{
        background: url("@/assets/images/AIchat/Banner/Insightify/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      .banner-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        max-width: 1080px;
        margin: 0 auto;
        padding: 100px 0;
        &.rowReverse{
          flex-direction: row-reverse;
        }
        .banner-left {
          width: 60%;
          height: auto;
          cursor: default;
  
          .title {
            margin-bottom: 30px;
            font-size: 60px;
            font-weight: 900;
            line-height: 1.1;
            color: var(--theme-AIChat-banner-title-color);
          }
          .des {
            font-size: 24px;
            color: var(--theme-AIChat-banner-des-color);
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--theme-AIChat-banner-btn-color);
            font-size: 20px;
            font-weight: bold;
            margin-top: 40px;
            background: var(--theme-AIChat-banner-btn-bg);
            border-radius: 6px;
            cursor: pointer;
          }
        }
  
        .banner-right {
          width: 45%;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      font-family: Arial, Arial;
      width: 100%;
      &.ChatCraft-AI-bg {
        background: url("@/assets/images/AIchat/Banner/ChatCraft-AI/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.TalkBuddy-Pro-bg{
        background: url("@/assets/images/AIchat/Banner/TalkBuddy-Pro/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.SynthChat-bg{
        background: url("@/assets/images/AIchat/Banner/SynthChat/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.IntelliTalk-bg{
        background: url("@/assets/images/AIchat/Banner/IntelliTalk/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.SmartChats-bg{
        background: url("@/assets/images/AIchat/Banner/SmartChats/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.SolvoChat-bg{
        background: url("@/assets/images/AIchat/Banner/SolvoChat/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ResponseRift-bg{
        background: url("@/assets/images/AIchat/Banner/ResponseRift/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.QuesTech-bg{
        background: url("@/assets/images/AIchat/Banner/QuesTech/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.QueryMate-bg{
        background: url("@/assets/images/AIchat/Banner/QueryMate/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.Insightify-bg{
        background: url("@/assets/images/AIchat/Banner/Insightify/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      .banner-box {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 0;
        .banner-left {
          padding: 0 .5rem;
          margin: 0 auto;
          text-align: center;
  
          .title {
            margin: 0 0 .2rem;
            padding: 0 .2rem;
            font-size: 0.56rem;
            color: var(--theme-AIChat-banner-title-color);
            line-height: .72rem;
          }
  
          .des {
            margin: .4rem auto;
            font-size: .32rem;
            color: var(--theme-AIChat-banner-des-color);
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--theme-AIChat-banner-btn-color);
            font-size: 20px;
            font-weight: bold;
            background: var(--theme-AIChat-banner-btn-bg);
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
            border-radius: 30px;
            margin: .5rem auto;
            cursor: pointer;
          }

        }
  
        .banner-right {
          width: 100%;
          margin: 0 auto;
          padding: 0 .5rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  </style>