<template>
  <div class="comment" :class="[{ right: content.right }]">
    <div class="content">
      <p class="title">{{ content.title }}</p>
      <div class="area">
        <div v-for="(item, index) in content.list" :key="index" class="item">
          <p class="subtitle">{{ item.subtitle }}</p>
          <div class="wrap">
            <img :src="$getFile(item.img)" v-if="item.img" alt="">
            <div class="user">
              <div class="name">{{ item.name }}</div>
              <div class="city">{{ item.city }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'AICartoon_Comment',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  }
}
</script>
  
<style scoped lang="scss">
.title {
  color: var(--AICartoon-comment-title-color);
}

.comment {
  background: var(--AICartoon-comment-bg);

  .area {

    .item {
      background-color: var(--AICartoon-comment-item-bg);
      box-shadow: var(--AICartoon-comment-item-boxshadow);
      border: var(--AICartoon-comment-item-border);

      .subtitle {
        color: var(--AICartoon-comment-item-subtitle-suffix);
      }

      .name {
        color: var(--AICartoon-comment-item-name-suffix);
      }

      .city {
        color: var(--AICartoon-comment-item-city-suffix);
      }

      &:hover {
        background: var(--AICartoon-comment-item-bg-hover, --theme-primary);
        box-shadow: var(--AICartoon-comment-item-boxshadow-hover);
        border: var(--AICartoon-comment-item-border-hover);

        .subtitle {
          color: var(--AICartoon-comment-item-subtitle-suffix-hover);
        }

        .name {
          color: var(--AICartoon-comment-item-name-suffix-hover);
        }

        .city {
          color: var(--AICartoon-comment-item-city-suffix-hover);
        }
      }
    }
  }

  &.right {
    .wrap {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }
  }

}

@media #{'only screen and (min-width: 992px)'} {
  .comment {
    width: 100%;

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 1080px;
      margin: 0 auto;
      padding: 80px 0;
      font-family: Arial, Arial;

      .title {
        margin-bottom: 12px;
        font-size: 60px;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        // width: 70%;
        margin: 0 auto 30px;
      }

      .area {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: 100%;
        margin-top: 42px;

        .item {
          position: relative;
          box-sizing: border-box;
          padding: 30px 20px;
          border-radius: 10px;
          overflow: hidden;
          transition: all .3s;
          overflow: visible;
          min-height: 424px;

          img {
            display: block;
            margin-right: 20px;
          }

          .wrap {
            display: flex;
            align-items: center;

            >img {
              border-radius: 50%;
            }
          }

          .subtitle {
            margin-bottom: 40px;
            font-size: 20px;
            line-height: 1.4;
            min-height: 250px;
          }

          .name {
            font-size: 20px;
            margin-bottom: 8px;
          }

          .city {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .comment {
    width: 100%;

    .content {
      width: 100%;
      padding: .8rem 0;
      font-family: Arial, Arial;

      .title {
        margin: 0 auto .5rem;
        font-size: .48rem;
        text-align: center;
        font-weight: 900;
      }

      .area {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: .5rem;

        .item {
          position: relative;
          padding: .5rem .4rem;
          border-radius: 10px;
          //   transition: all .3s;
          overflow: hidden;
          margin: 0 .3rem;

          img {
            display: block;
            margin-right: .3rem;
          }

          .wrap {
            display: flex;
            align-items: center;
          }

          .subtitle {
            margin-bottom: .3rem;
            line-height: 1.4;
            font-size: .3rem;
          }

          .name {
            margin-bottom: .1rem;
            font-size: .4rem;
            // line-height: 1.2;
          }

          .city {
            font-size: .3rem;
          }
        }
      }
    }
  }

}
</style>