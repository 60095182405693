<template>
    <!-- 问题组件 -->
    <div class="koutu-question-container" :style="content.style || {}">
      <div class="question-main">
        <component :is="content.tag || 'h2'" class="title">{{ content.title }}</component>
        <div class="question-list">
          <div v-for="(item, idx) in content.list" :key="idx" class="question-item">
            <div :class="['question-top', activeIdx === idx && 'active']" @click="checkedActive(idx)">
              <div
                class="item-title"
              >
                {{ item.title }}
              </div>
              <div class="item-icon">
                <img class="pc-icon" :style="{ transform: activeIdx === idx ? 'rotate(-0)' : 'rotate(180deg)' }"
                  :src="$getFile('questions/question-close-icon.svg')" alt="">
                <!-- <img class="mobile-icon" :style="{ transform: activeIdx === idx? 'rotate(0)' : 'rotate(-180deg)' }" src="~/static/images/question-top.webp"> -->
                <img class="mobile-icon" :style="{ transform: activeIdx === idx ? 'rotate(0)' : 'rotate(180deg)' }"
                  :src="$getFile('questions/question-close-icon.svg')" alt="">
              </div>
            </div>
            <template v-for="(des, dIdx) in item.desList">
              <div ref="default" :key="'d' + dIdx" :class="['default-des']">
                <div ref="question" :class="['question-des', activeIdx === idx && 'question-active']">
                  <span v-html="des.des" />
                  <ul>
                    <li v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des">
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Koutu_Questions',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
    data() {
      return {
        activeIdx: '',
        showMobileHeader: false
      }
    },
    onLoad() {
    },
    methods: {
      checkedActive(idx) {
        this.activeIdx = this.activeIdx === idx ? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
        this.$refs.default.forEach(el => {
          el.style.height = el.style.paddingBottom = 0
        })
        if (this.activeIdx === idx) {
          const activeEl = this.$refs.default[idx]
          activeEl.style.height = this.$refs.question[idx].offsetHeight + 'px'
          activeEl.style.paddingBottom = '20px'
        }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
 @media #{'only screen and (min-width: 992px)'} {
    .koutu-question-container {
      font-family: Arial, Arial;
      .question-main {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        padding: 60px 0;
  
        .title {
          margin: 0 0 48px;
          font-size: 60px;
          font-weight: 900;
          color: var(--theme-primary-black);
          width: 80%;
          line-height: 1.3;
          margin: 0 auto 40px;
          text-align: center;
        }
  
        .question-list {
          background-color: var(--theme-primary-white);
          // padding: 0 30px;
          border-radius: 12px;
          .question-item {
            &:not(:last-child) {
              border-bottom: 1px solid #EDEDED;
            }
  
            .question-top {
              display: flex;
              justify-content: space-between;
              padding: 32px 0;
              line-height: 52px;
              transition: all 200ms;
              cursor: pointer;
  
              &:hover {
                .item-title {
                  color: var(--theme-primary-suffix);
                }
              }
  
              .item-title {
                font-size: 20px;
                font-weight: bold;
                color: var(--theme-primary-black);
              }
  
              .item-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: auto;
                text-align: center;
                cursor: pointer;
  
                .pc-icon {
                  width: 35px;
                  height: 35px;
                  transition: all 200ms;
                }
  
                .mobile-icon {
                  display: none;
                  width: 16px;
                  height: 16px;
                  transition: all 200ms;
                }
              }
  
            }
  
            .active {
              padding-bottom: 12px;
  
              .item-title {
                // color: var(--theme-primary-suffix);
              }
            }
  
            .default-des {
              height: 0;
              overflow: hidden;
              transition: all 0.25s ease-out;
  
              .question-des {
                display: block;
                opacity: 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.5;
                color: var(--theme-primary-tiny);
                box-sizing: border-box;
                transition: all 0.25s ease-out;
              }
  
              .question-active {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .koutu-question-container {
      font-family: Arial, Arial;
      -webkit-tap-highlight-color: transparent;
  
      .question-main {
        padding: 1.2rem 0.31rem;
  
        .title {
          margin-bottom: .5rem;
          font-size: .48rem;
          color: var(--theme-font-question-title);
          line-height: .72rem;
          text-align: center;
          font-weight: 900;
        }
  
        .question-list {
          background-color: var(--theme-primary-white);
          padding: 0 .1rem;
          // border-radius: 10px;
          .question-item {
            &:not(:last-child) {
              border-bottom: 1px solid #EDEDED;
            }
  
            .question-top {
              display: flex;
              justify-content: space-between;
              padding: .46rem 0 0;
              line-height: .64rem;
              cursor: pointer;
              transition: all 200ms;
  
              &.active {
                // color: var(--theme-primary-suffix);
              }
  
              .item-title {
                font-size: .32rem;
                font-weight: 500;
                color: var(--theme-primary-black);
                line-height: .48rem;
              }
  
              .item-icon {
                display: flex;
                align-items: center;
                flex: none;
                width: .5rem;
                height: .5rem;
                margin-left: .32rem;
                padding-top: .1rem;
  
  
                .pc-icon {
                  display: none;
                }
  
                .mobile-icon {
                  width: 100%;
                  height: 100%;
                  transition: all 200ms;
                }
              }
            }
  
            .default-des {
              height: 0;
              margin-top: .48rem;
              overflow: hidden;
              transition: all .25s ease-out;
  
              .question-des {
                display: block;
                opacity: 0;
                font-size: .28rem;
                line-height: .42rem;
                color: var(--theme-font-question-tiny);
                box-sizing: border-box;
                transition: all .25s ease-out;
              }
  
              .question-active {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
  </style>
  