const Banner = {
  "--AICartoon-banner-title-color": "#FFFFFF",
  "--AICartoon-banner-subtitle-color": "#FFFFFF",

  "--AICartoon-banner-upload-bg": "#4501DE",
  "--AICartoon-banner-upload-border": "2px dashed #3C8598",
  "--AICartoon-banner-upload-shadow": "8px solid #4E03F7",

  "--AICartoon-banner-upload-btn-color": "#fff",
  "--AICartoon-banner-upload-btn-shadow": "",
  "--AICartoon-banner-upload-btn-bg": "#FF8C19",

  "--AICartoon-banner-upload-max-color": "#808080",

  "--AICartoon-banner-upload-tips-color": "#B3B3B3",
}

const Price = {
  "--AICartoon-price-title-color": "#252325",

  "--AICartoon-price-item-shadow": "none",
  "--AICartoon-price-item-shadow-hover": "none",
  "--AICartoon-price-item-border": "3px solid #77E4FF",
  "--AICartoon-price-item-border-hover": "3px solid #77E4FF",

  // 价格顶部
  "--AICartoon-price-tag-bg": "#F2F4F3",
  "--AICartoon-price-tag-bg-hover": "#F2F4F3",
  "--AICartoon-price-tag-suffix": "#252325",
  "--AICartoon-price-tag-suffix-hover": "#252325",
  // 详情模块颜色
  "--AICartoon-price-main-bg": "#F2F4F3",
  "--AICartoon-price-main-bg-hover": "#F2F4F3",
  // 价格颜色
  "--AICartoon-price-money-suffix": "#101012",
  "--AICartoon-price-money-suffix-hover": "#101012",
  // 套餐详情颜色
  "--AICartoon-price-desc-suffix": "#101012",
  "--AICartoon-price-desc-suffix-hover": "#101012",
  // 购买按钮
  "--AICartoon-price-pay-button-suffix": "#101012",
  "--AICartoon-price-pay-button-bg": "#77E4FF",
  "--AICartoon-price-pay-button-shadow": "none",
  "--AICartoon-price-pay-button-bg-hover":"#77E4FF",
  // 套餐 tips
  "--AICartoon-price-tip-suffix": "#252325",
  "--AICartoon-price-tip-suffix-hover": "#252325",
  "--AICartoon-price-tip-bg": "#F2F4F3",
  "--AICartoon-price-tip-bg-hover": "#F2F4F3",

}

const Questions = {
  "--AICartoon-questions-title-color": "#252325",

  "--AICartoon-questions-item-bg": "#F2F4F3",
  "--AICartoon-questions-item-bg-open": "#77E4FF",

  "--AICartoon-questions-item-boxshadow": "none",
  "--AICartoon-questions-item-boxshadow-open": "none",

  "--AICartoon-questions-item-border": "3px solid #77E4FF",
  "--AICartoon-questions-item-border-open": "3px solid #000000",

  "--AICartoon-questions-item-number-suffix": "#4C3626",

  "--AICartoon-questions-item-suffix": "#101012",
  "--AICartoon-questions-item-suffix-open": "#101012",

  "--AICartoon-questions-item-des-suffix": '#252325',
}

const Comment = {
  "--AICartoon-comment-title-color": "#252325",

  "--AICartoon-comment-bg": "#fff",

  "--AICartoon-comment-item-bg": "#fff",
  "--AICartoon-comment-item-bg-hover": "#77E4FF",

  "--AICartoon-comment-item-border": "3px solid #77E4FF",
  "--AICartoon-comment-item-border-hover": "3px solid #000000",

  "--AICartoon-comment-item-boxshadow": "none",
  "--AICartoon-comment-item-boxshadow-hover": "none",

  "--AICartoon-comment-item-subtitle-suffix": "#252325",
  "--AICartoon-comment-item-subtitle-suffix-hover": "#252325",

  "--AICartoon-comment-item-name-suffix": "#4C484C",
  "--AICartoon-comment-item-name-suffix-hover": "#4C484C",

  "--AICartoon-comment-item-city-suffix": "#999199",
  "--AICartoon-comment-item-city-suffix-hover": "#999199",
}

const Case = {
  "--AICartoon-case-title-color": "#252325",

  "--AICartoon-case-bg": "#fff",
  "--AICartoon-case-item-bg": "#F2F4F3",
  "--AICartoon-case-item-bg-hover": "#77E4FF",

  "--AICartoon-case-item-title-color": "#252525",
  "--AICartoon-case-item-title-color-hover": "#252525",
  "--AICartoon-case-item-desc-color": "#4C484C",
  "--AICartoon-case-item-desc-color-hover": "#4C484C",

  "--AICartoon-case-item-shadow": "none",
  "--AICartoon-case-item-shadow-hover": "none",
  "--AICartoon-case-item-border": "3px solid #77E4FF",
  "--AICartoon-case-item-border-hover": "3px solid #4C484C",
}

const Step = {
  "--AICartoon-step-title-color": "#fff",

  "--AICartoon-step-bg": '#252325',
  "--AICartoon-step-item-bg": "#121212",
  "--AICartoon-step-item-bg-hover": "#77E4FF",

  "--AICartoon-step-item-title-color": "#77E4FF",
  "--AICartoon-step-item-title-color-hover": "#121212",

  "--AICartoon-step-item-subtitle-color": "#FFFFFF",
  "--AICartoon-step-item-subtitle-color-hover": "#121212",

  "--AICartoon-step-item-shadow": "none",
  "--AICartoon-step-item-shadow-hover": "none",
  "--AICartoon-step-item-border": "2px solid #121212",
  "--AICartoon-step-item-border-hover": "2px solid #FFDFDF",
}

export const AICartoonBigCyan = {
  ...Banner,
  ...Price,
  ...Questions,
  ...Comment,
  ...Case,
  ...Step
}