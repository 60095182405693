const Price = {
  // pdf价格
  "--theme-pdf-price-tag-bg": "",
  "--theme-pdf-price-tag-bg-hover": "",
  "--theme-pdf-price-tag-color": "#111112",
  "--theme-pdf-price-tag-color-hover": "#111112",

  "--theme-pdf-price-li-color": "#4B4B4C",
  "--theme-pdf-price-li-color-hover": "#4B4B4C",

  "--theme-pdf-price-tips-color": "#7D7D7F",
  "--theme-pdf-price-tips-color-hover": "#FF6B30",
  "--theme-pdf-price-tips-bg": "#E2E2E5",
  "--theme-pdf-price-tips-bg-hover": "rgba(255, 107, 48, 0.2)",

  "--theme-pdf-price-color": "#FF6B30",
  "--theme-pdf-price-color-hover": "#FF6B30",

  "--theme-pdf-price-inner-bg": "#F2F4F3",
  "--theme-pdf-price-inner-bg-hover": "linear-gradient(180deg, #FFF8C1 0%, #F0FFDB 100%)",

  "--theme-pdf-price-btn": "#FFE000",
  "--theme-pdf-price-btn-hover": "#FFE000",

  "--theme-pdf-price-btn-color": "#111112",
  "--theme-pdf-price-btn-color-hover": "#111112",
}

const Questions = {
  // PDF
  // PDF quetions
  "--theme-pdf-questions-title-color": "#FF6B30",
  "--theme-pdf-quesions-bg": "#F2F4F3",
  "--theme-pdf-quesions-inner-bg": "#F2F4F3",

  "--theme-pdf-quesions-item-bg": "#F2F4F3",
  "--theme-pdf-quesions-item-title-color": "#111112",
  "--theme-pdf-quesions-item-title-color-hover": "#FF6B30",

  "--theme-pdf-question-icon-filter": "",

  "--theme-pdf-quesions-item-des-color": "rgba(17, 17, 18, 0.7)",
}


const Steps = {
  "--theme-pdf-step-bg": "#F2F4F3",
  "--theme-pdf-step-subtitle-color": "#111112",
  "--theme-pdf-step-inner-bg": "#fff"
}

const Explain = {
  "--theme-pdf-explain-inner-bg": "white",
}


const Comment = {
  "--theme-pdf-comment-item-bg": "#FFFFFF",
  "--theme-pdf-comment-city-color": "#7D7D7F",
  "--theme-pdf-comment-name-color": "#111112",
  "--theme-pdf-comment-subtitle-color": "#111112",

  "--theme-pdf-comment-item-bg-hover": "#FF6B30",
  "--theme-pdf-comment-city-color-hover": "#FFF",
  "--theme-pdf-comment-name-color-hover": "#FFF",
  "--theme-pdf-comment-subtitle-color-hover": "#FFF",
}

export const coustomPDFgoldenYellowTheme = {
  //主题背景颜色
  "--theme-primary": "#FFE000",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#FFE000",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#111112",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#FFE000",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...Price,
  ...Questions,
  ...Steps,
  ...Explain,
  ...Comment
}