<template>
    <div :class="['PDF-upload', content.className]">
        <div class="wrap">
            <div class="title">{{ content.title }}</div>
            <div class="subTitle">{{ content.subTitle }}</div>
            <div class="content">
                <ul>
                    <li v-for="( line, index ) in  content.desList " @click="cur = index"
                        :class="{ 'active': cur === index }" :key="index">
                        {{ line }}
                    </li>
                </ul>
                <div v-if="pdfName" class="pdf">{{ pdfName }}</div>
                <el-upload class="pic-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                    :on-change="handleAvatarChangeIcon">

                    <div class="uploadBtn"><span>{{ content.btnText }}</span></div>
                </el-upload>


            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'PDF_Upload',
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { }
        }
    },
    data() {
        return {
            pdfName: '',
            cur: 0
        }
    },
    methods: {
        handleAvatarChangeIcon(file) {//选中文件触发的change事件
            // console.log(file)
            const isPDF = file.raw.type === 'application/pdf'
            // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
            if (!isPDF) {
                this.$message.error('Upload images can only be in PDF format!')
                return false
            } else {
                this.pdfName = file.raw.name;
            }
        }

    }
}
</script>
  
<style scoped lang="scss">
.PDF-upload {
    background: var(--theme-primary-koutu-upload-bg);

    &.PDFConvert {
        .wrap {
            .content {
                background: #F2F4F3 !important;

                ul {
                    li {
                        color: #4D4D4D !important;
                    }
                }
            }

            .title {
                color: #4C3626 !important;
            }

            .subTitle {
                color: #4C3626 !important;
            }

            .uploadBtn {
                background: #FF612F !important;
            }
        }
    }

    &.PDFSyncSuite {
        background: url("@/assets/images/pdf/banner/PDFSyncSuite/bg.jpg") no-repeat top center;

        .wrap {

            .content {
                box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
            }

            .title {
                color: #FFFFFF !important;
            }

            .subTitle {
                color: rgba($color: #FFFFFF, $alpha: 0.7) !important;
            }

            .uploadBtn {
                background: #FF9B52 !important;
            }
        }
    }

    &.FlexiFormatPro {
        background: url("@/assets/images/pdf/banner/FlexiFormatPro/bg.jpg") no-repeat top center;

        .wrap {

            .content {
                box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
            }

            .title {
                color: #FFFFFF !important;
            }

            .subTitle {
                color: rgba($color: #FFFFFF, $alpha: 0.7) !important;
            }

            .uploadBtn {
                color: #111112 !important;
            }
        }
    }

    &.PowerFormatPro {
        background: url("@/assets/images/pdf/banner/PowerFormatPro/bg.jpg") no-repeat top center;

        .wrap {

            .content {
                box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
            }

            .title {
                color: #FFFFFF !important;
            }

            .subTitle {
                color: rgba($color: #FFFFFF, $alpha: 0.7) !important;
            }
        }
    }

    &.PDFMaster {
        background: url("@/assets/images/pdf/banner/PDFMaster/bg.jpg") no-repeat top center;

        .wrap {

            .title {
                color: #FFFFFF !important;
            }

            .subTitle {
                color: #FFFFFF !important;
            }
        }
    }

    &.PDFFlexSuite {
        background: url("@/assets/images/pdf/banner/PDFFlexSuite/bg.jpg") no-repeat top center;

        .wrap {

            .content {
                background: #101112 !important;

                ul {
                    li {
                        color: #7D7D7F !important;
                    }
                }
            }

            .title {
                color: #FFFFFF !important;
            }

            .subTitle {
                color: #7D7D7F !important;
            }
        }
    }

    &.ConvertPDF {
        background: url("@/assets/images/pdf/banner/ConvertPDF/bg.png") no-repeat top center;

        .wrap {

            .title {
                color: #FD2C52 !important;
            }

            .subTitle {
                color: #7D7D7F !important;
            }
        }
    }

    &.PDFMorph {
        background: url("@/assets/images/pdf/banner/PDFMorph/bg.jpg") no-repeat top center;

        .wrap {

            .title {
                color: #034EF3 !important;
            }

            .subTitle {
                color: #111112 !important;
            }
        }
    }

    &.TransPDFSuite {
        background: url("@/assets/images/pdf/banner/TransPDFSuite/bg.jpg") no-repeat top center;

        .wrap {

            .title {
                color: #fff !important;
            }

            .subTitle {
                color: #fff !important;
            }
        }
    }

    &.cutieballfa {
        background: url("@/assets/images/pdf/banner/PDFShiftPro/bg.jpg") no-repeat top center;

        .wrap {

            .subTitle {
                color: #01002C !important;
            }
        }
    }
}


@media #{'only screen and (min-width: 992px)'} {
    .PDF-upload {
        font-family: Arial, Arial;

        .wrap {
            max-width: 1080px;
            margin: 0 auto;
            padding: 100px 0;

            .title {
                font-size: 60px;
                font-weight: 900;
                color: var(--theme-primary-black);
                line-height: 1.3;
                text-align: center;
                width: 100%;
                margin: 0 auto 25px;
            }

            .subTitle {
                font-size: 30px;
                color: #999999;
                text-align: center;
                margin-bottom: 40px;
            }

            .content {
                background-color: white;
                border-radius: 15px;

                ul {
                    list-style: none;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    border-bottom: 1px solid #DEE0DF;
                    margin-bottom: 30px;

                    // gap: 40px;
                    li {
                        font-size: 20px;
                        padding: 20px 0;
                        text-align: center;
                        border-right: 1px solid #DEE0DF;
                        color: #4C484C;
                        cursor: pointer;

                        &.active {
                            color: var(--theme-primary-suffix) !important;
                        }

                        &:last-child {
                            border-right: none;
                        }

                        &::marker {
                            color: #D9D9D9;
                        }

                        &:hover {
                            color: var(--theme-primary-suffix) !important;
                        }
                    }
                }

                .pdf {
                    text-align: center;
                    font-size: 20px;
                }

                .pic-uploader {
                    text-align: center;
                    padding: 30px 0 80px;

                    .uploadBtn {
                        padding: 20px 150px;
                        font-size: 26px;
                        display: flex;
                        align-items: center;
                        color: white;
                        font-weight: bold;
                        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
                        background: var(--theme-primary);
                        border-radius: 40px;

                        span {
                            margin-left: 10px;
                        }
                    }
                }

            }
        }
    }
}

@media #{'only screen and (max-width: 991px)'} {
    .PDF-upload {
        background: var(--theme-primary-koutu-upload-bg);
        font-family: Arial, Arial;

        .wrap {
            width: 100%;
            margin: 0 auto;
            padding: 1rem 0;

            .title {
                font-size: .48rem;
                font-weight: 900;
                color: var(--theme-primary-black);
                line-height: 1.3;
                text-align: center;
                width: 100%;
                margin: 0 auto .2rem;
            }

            .subTitle {
                font-size: .3rem;
                color: #999999;
                text-align: center;
                margin-bottom: .5rem;
            }

            .content {
                background-color: white;
                border-radius: .2rem;
                margin: 0 .3rem;

                ul {
                    list-style: none;
                    display: grid;
                    padding: .5rem 0;
                    grid-template-columns: repeat(1, 1fr);
                    border-bottom: 1px solid #DEE0DF;
                    margin-bottom: .5rem;

                    li {
                        font-size: .3rem;
                        line-height: 2;
                        color: #4C484C;
                        text-align: center;

                        &::marker {
                            color: #D9D9D9;
                        }

                        &.active {
                            color: var(--theme-primary-suffix) !important;
                        }
                    }
                }

                .pdf {
                    text-align: center;
                    font-size: .3rem;
                }

                .pic-uploader {
                    text-align: center;
                    padding: 0 0 1rem;

                    .uploadBtn {
                        padding: .25rem 1rem;
                        font-size: .3rem;
                        display: flex;
                        align-items: center;
                        color: white;
                        font-weight: bold;
                        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
                        background: var(--theme-primary);
                        border-radius: .8rem;

                        span {
                            margin-left: .2rem;
                        }
                    }
                }

            }
        }
    }
}
</style>