<template>
    <div class="wrap">
        <div class="pdf_step">
        <p class="title">{{ content.title }}</p>
        <div class="area">
            <div v-for="(item, index) in content.list" :key="index" class="item">
                <div class="icon" v-if="item.img">
                    <img :src="$getFile(item.img)" alt="">
                </div>
                <div class="subtitle">{{ item.stepText }}</div>
                <p class="explain">{{ item.text }}</p>
            </div>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PDF_Step',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
 @media #{'only screen and (min-width: 992px)'} {
    .wrap {
        background: var(--theme-pdf-step-bg);
        .pdf_step {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 1080px;
            margin: 0 auto;
            padding: 100px 0;
            font-family: Arial, Arial;
            .title {
                margin-bottom: 12px;
                font-size: 60px;
                font-weight: 900;
                color: var(--theme-primary-black);
                line-height: 1.3;
                text-align: center;
                width: 80%;
                margin: 0 auto 40px;
            }
        
            .area {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 40px;
                width: 100%;
                margin-top: 42px;
        
                .item {
                position: relative;
                padding: 40px 20px;
                background: var(--theme-pdf-step-inner-bg);
                box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
                border-radius: 10px;
                overflow: hidden;
                transition: all .3s;
                overflow: visible;

                .icon{
                    margin-bottom: 10px;
                }

                .subtitle {
                    margin-bottom: 10px;
                    font-size: 25px;
                    font-weight: bold;
                    color: var(--theme-primary-suffix);
                    line-height: 1.2;
                    text-transform: uppercase;
                }
                .explain {
                    margin-top: 20px;
                    font-size: 20px;
                    color: var(--theme-pdf-step-subtitle-color, --theme-primary-black);
                    line-height: 1.2;
                }
              }
            }
        }
    }
   
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .wrap {
        background: var(--theme-pdf-step-bg);
        .pdf_step {
            width: 100%;
            padding: .8rem 0;
            background: var(--theme-pdf-step-bg);
            font-family: Arial, Arial;
            .title {
                margin: 0 auto 1rem;
                font-size: .48rem;
                color: var(--theme-primary-black);
                text-align: center;
                font-weight: 900;
                padding: 0 .2rem;
            }
            .area {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: .5rem;
                margin-top: 1rem;
        
                .item {
                position: relative;
                padding: .8rem .4rem;
                background: var(--theme-pdf-step-inner-bg);
                border-radius: .2rem;
                //   transition: all .3s;
                overflow: visible;
                margin: 0 .3rem;
                .icon{
                    margin-bottom: 5px;
                }
                .subtitle {
                    margin-bottom: .3rem;
                    font-size: .5rem;
                    font-weight: bold;
                    color: var(--theme-primary-suffix);
                    text-transform: uppercase;
                }
                .explain {
                    // margin-top: 20px;
                    font-size: .3rem;
                    color: var(--theme-pdf-step-subtitle-color, --theme-primary-black);
                    // line-height: 1.2;
                }
                }
            }
        }
    }
  }
  </style>