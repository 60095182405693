<template>
  <div class="wrap">
    <div class="inner">
      <div class="block-title">{{ content.title }}</div>
      <div class="step-list">
        <div class="step-item" v-for="v of content.step_arr" :key="v.title">
          <div class="step-img">
            <img :src="$getFile(v.step_img)" alt="">
          </div>
          <div class="step-text">
            <div class="step-name">{{ v.step_name }}</div>
            <div class="step-desc">{{ v.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AICartoon_Step',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.block-title {
  color: var(--AICartoon-step-title-color);
}

.step-item {
  background-color: var(--AICartoon-step-item-bg);
  box-shadow: var(--AICartoon-step-item-shadow);
  border: var(--AICartoon-step-item-border);

  .step-name {
    color: var(--AICartoon-step-item-title-color, --theme-primary-black);
  }

  .step-desc {
    color: var(--AICartoon-step-item-subtitle-color, #4C484C);
  }

  &:hover {
    background: var(--AICartoon-step-item-bg-hover);
    box-shadow: var(--AICartoon-step-item-shadow-hover);
    border: var(--AICartoon-step-item-border-hover);


    .step-name {
      color: var(--AICartoon-step-item-title-color-hover, --theme-primary-black);
    }

    .step-desc {
      color: var(--AICartoon-step-item-subtitle-color-hover, #4C484C);
    }
  }
}

@media #{'only screen and (min-width: 992px)'} {
  .wrap {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;
    background: var(--AICartoon-step-bg);

    .inner {
      max-width: 1080px;
      margin: 0 auto;
      padding: 130px 0;

      .block-title {
        font-size: 60px;
        font-weight: bold;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 32px;
        font-weight: 900;
      }

      .step-list {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 24px;


        .step-item {
          box-sizing: content-box;
          padding: 22px;
          border-radius: 28px 28px 28px 28px;


          .step-img {
            width: 100%;

            >img {
              width: 100%;
            }
          }

          .step-text {
            box-sizing: border-box;
            padding: 28px 0 0;
            min-height: 206px;

            .step-name {
              font-size: 24px;
              font-weight: bold;
              line-height: 24px;
              margin-bottom: 20px;
            }

            .step-desc {
              font-size: 20px;
              font-weight: 400;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .wrap {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;
    background: var(--AICartoon-step-bg);

    .inner {
      width: 100%;
      margin: 0 auto;
      padding: .7rem 0;

      .block-title {
        font-size: .48rem;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        font-weight: 900;
        margin-bottom: .5rem;
      }

      .step-list {
        display: grid;
        grid-template-columns: 98%;
        margin: 5%;

        .step-item {
          box-sizing: content-box;
          padding: .2rem;
          margin-bottom: .50rem;
          border-radius: .28rem .28rem .28rem .28rem;

          .step-img {
            width: 100%;
            margin-bottom: 0.24rem;

            img {
              width: 100%;
            }
          }

          .step-text {
            min-height: 2.03rem;

            .step-name {
              font-size: .32rem;
              font-weight: bold;
              line-height: .38rem;
              margin-bottom: .24rem;
            }

            .step-desc {
              font-size: .24rem;
              font-weight: 400;
              line-height: .36rem;
            }
          }
        }
      }
    }
  }
}
</style>