const Price = {
  // pdf价格
  "--theme-pdf-price-tag-bg": "#101112",
  "--theme-pdf-price-tag-bg-hover": "#101112",
  "--theme-pdf-price-tag-color": "#FFFFFF",
  "--theme-pdf-price-tag-color-hover": "#FFFFFF",

  "--theme-pdf-price-li-color": "#111112",
  "--theme-pdf-price-li-color-hover": "#111112",

  "--theme-pdf-price-tips-color": "#7D7D7F",
  "--theme-pdf-price-tips-color-hover": "#7D7D7F",
  "--theme-pdf-price-tips-bg": "#fff",
  "--theme-pdf-price-tips-bg-hover": "#fff",

  "--theme-pdf-price-color": "#FF612F",
  "--theme-pdf-price-color-hover": "#FF612F",

  "--theme-pdf-price-inner-bg": "#fff",
  "--theme-pdf-price-inner-bg-hover": "#fff",

  "--theme-pdf-price-btn": "#77E4FF",
  "--theme-pdf-price-btn-hover": "#77E4FF",

  "--theme-pdf-price-btn-color": "#111112",
  "--theme-pdf-price-btn-color-hover": "#111112",
}

const Questions = {
  // PDF
  // PDF quetions
  "--theme-pdf-questions-title-color": "#77E4FF",
  "--theme-pdf-quesions-bg": "#101112",
  "--theme-pdf-quesions-inner-bg": "#101112",

  "--theme-pdf-quesions-item-bg": "#101112",
  "--theme-pdf-quesions-item-title-color": "#fff",
  "--theme-pdf-quesions-item-title-color-hover": "#fff",

  "--theme-pdf-question-icon-filter": "brightness(100)",

  "--theme-pdf-quesions-item-des-color": "rgba(255,255,255,0.7)",
}


const Steps = {
  "--theme-pdf-step-bg": "#F2F4F3",
  "--theme-pdf-step-subtitle-color": "#fff",
  "--theme-pdf-step-inner-bg": "#101112"
}

const Explain = {
  "--theme-pdf-explain-inner-bg": "white",
}


const Comment = {
  "--theme-pdf-comment-item-bg": "#F2F4F3",
  "--theme-pdf-comment-city-color": "#7D7D7F",
  "--theme-pdf-comment-name-color": "#111112",
  "--theme-pdf-comment-subtitle-color": "#111112",


  "--theme-pdf-comment-item-bg-hover": "#101112",
  "--theme-pdf-comment-city-color-hover": "#AFAFB2",
  "--theme-pdf-comment-name-color-hover": "#77E4FF",
  "--theme-pdf-comment-subtitle-color-hover": "#77E4FF",
}


export const PDFblackCyan = {
  ...Price,
  ...Questions,
  ...Steps,
  ...Explain,
  ...Comment
}