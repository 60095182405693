//导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "@/views/index";
import SubIndex from "@/views/subIndex";
import Payout from "@/views/payout";
import Protocol from "@/views/protocol";

//注册路由组件
Vue.use(VueRouter);
//编写路由规则
const routes = [
  {
    path: "/",
    name: 'Home',
    component: Home
  }, {
    path: "/sub/:id",
    name: 'SubIndex',
    component: SubIndex
  }, {
    path: "/protocol/:id",
    name: 'Protocol',
    component: Protocol
  },
  {
    path: "/payout/:id",
    name: 'Payout',
    component: Payout
  }
]
//创建路由
const router = new VueRouter({
  mode: 'history',
  routes
})
//导出
export default router;