<template>
  <div class="wrap">
    <div class="inner">
      <div class="block-title">{{ content.title }}</div>
      <div class="case-list">
        <div class="case-item" v-for="v of content.case_arr" :key="v.title">
          <div class="case-img">
            <img :src="$getFile(v.img)" alt="">
          </div>
          <div class="case-text">
            <div class="case-title">{{ v.title }}</div>
            <div class="case-desc">{{ v.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AICartoon_Case',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  }
}
</script>


<style scoped lang="scss">
.block-title {
  color: var(--AICartoon-case-title-color);
}
.case-item {
  background-color: var(--AICartoon-case-item-bg);
  box-shadow: var(--AICartoon-case-item-shadow);
  border: var(--AICartoon-case-item-border);

  &:hover {
    background: var(--AICartoon-case-item-bg-hover, --theme-primary);
    box-shadow: var(--AICartoon-case-item-shadow-hover);
    border: var(--AICartoon-case-item-border-hover);

    .case-title {
      color: var(--AICartoon-case-item-title-color-hover);
    }

    .case-desc {
      color: var(--AICartoon-case-item-desc-color-hover);
    }
  }

  .case-title {
    color: var(--AICartoon-case-item-title-color);
  }

  .case-desc {
    color: var(--AICartoon-case-item-desc-color);
  }
}

@media #{'only screen and (min-width: 992px)'} {
  .wrap {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;
    background: var(--AICartoon-case-bg);

    .inner {
      max-width: 1080px;
      margin: 0 auto;
      padding: 130px 0;

      .block-title {
        font-size: 60px;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 50px;
      }

      .case-list {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 24px;


        .case-item {
          box-sizing: content-box;
          padding: 24px;
          border-radius: 28px 28px 28px 28px;

          .case-img {
            margin-bottom: 24px;

            >img {
              border-radius: 16px;
              width: 100%;
            }
          }

          .case-text {
            min-height: 270px;

            .case-title {
              font-size: 28px;
              font-weight: bold;
              line-height: 36px;
              margin-bottom: 12px;
            }

            .case-desc {
              font-size: 20px;
              font-weight: 400;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .wrap {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;
    background: var(--AICartoon-case-bg);

    .inner {
      width: 100%;
      margin: 0 auto;
      padding: .7rem 0 1.3rem;

      .block-title {
        font-size: .48rem;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        margin-bottom: .5rem;
      }

      .case-list {
        display: grid;
        grid-template-columns: 98%;
        gap: 1%;
        margin: 5%;

        .case-item {
          box-sizing: content-box;
          padding: .24rem;
          border-radius: .28rem .28rem .28rem .28rem;

          .case-img {
            width: 100%;
            margin-bottom: 0.24rem;

            img {
              border-radius: 16px;
              width: 100%;
            }
          }

          .case-text {
            min-height: 2.7rem;

            .case-title {
              font-size: .32rem;
              font-weight: bold;
              line-height: .36rem;
              margin-bottom: .12rem;
            }

            .case-desc {
              font-size: .24rem;
              font-weight: 400;
              line-height: .32rem;
            }
          }
        }
      }
    }
  }
}
</style>