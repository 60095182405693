export const AIChatQingSe = {
       // 模块配置
    // AIChat - banner
    "--theme-AIChat-banner-title-color": "white",
    "--theme-AIChat-banner-des-color": "white",
    "--theme-AIChat-banner-btn-color": "white",
    "--theme-AIChat-banner-btn-bg": "linear-gradient(132deg, #0195C3 0%, #00CAA8 100%)",
    // AIChat - explain
    "--theme-AIChat-explain-bg": "white",
    "--theme-AIChat-explain-inner-bg": "white",
    "--theme-AIChat-explain-item-shadow": "0px 20px 40px 0px rgba(0,0,0,0.15)",
    "--theme-AIChat-explain-inner-bg-hover": "#17A18A",
    // AIChat - step
    "--theme-AIChat-step-bg": "#26D7B9",
    "--theme-AIChat-step-inner-bg": "white",
    "--theme-AIChat-step-inner-bg-hover": "#17A18A",
    "--theme-AIChat-step-title-color": "white",
    "--theme-AIChat-step-inner-title-color": "#26D7B9",
    "--theme-AIChat-step-inner-desc-color": "#252325",
    "--theme-AIChat-step-inner-title-color-hover": "white",
    "--theme-AIChat-step-inner-desc-color-hover": "white",
    "--theme-AIChat-step-radius": "10px",
    // AIChat - price
    "--theme-AIChat-price-btn-bg": "linear-gradient(132deg, #0195C3 0%, #00CAA8 100%)",
    "--theme-AIChat-price-btn-color": "white",
    "--theme-AIChat-price-btn-color-hover": "#252325",
    "--theme-AIChat-price-btn-bg-hover": "#FFE349",
    "--theme-AIChat-price-text-color": "#252325",
    "--theme-AIChat-price-text-color-hover": "#252325",
    "--theme-AIChat-price-ul-text-align": "left",
    "--theme-AIChat-price-item-bg": "white",
    "--theme-AIChat-price-item-bg-hover": "#26D7B9",
    "--theme-AIChat-price-item-border-radius": "10px",
    "--theme-AIChat-price-light": "#ADEFE4",
    "--theme-AIChat-price-deep": "#17A18A",
    // AIChat - question
    "--theme-AIChat-question-bg": "white",
    // AIChat - comment
    "--theme-AIChat-comment-border-radius": "20px",
    "--theme-AIChat-comment-item-bg-hover": "#26D7B9",

  }