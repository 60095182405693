<template>
    <!-- 首页banner 组件 -->
    <div class="banner-container " :class="[{'DialogGenius-bg': content.DialogGenius},{'ChatterMinds-bg': content.ChatterMinds},{'ConvoCraft-bg': content.ConvoCraft},{'ChatMind-bg': content.ChatMind},{'AIConvo-bg': content.AIConvo}]">
      <div class="banner-box" :class="[{'rowReverse': content.rowReverse}]">
        <div class="banner-left">
          <h1 class="title">{{ content.title }}</h1>
          <p class="des">{{ content.des }}</p>
          <a :href="content.link"> 
            <div class="btn">{{ content.btnText }}</div>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AIChat_Banner_2',
    props: {
      content: {
        type: Object,
        required: true,
        default: () => { }
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
@media #{'only screen and (min-width: 992px)'} {
    .banner-container {
      overflow: hidden;
      font-family: Arial, Arial;
      width: 100%;
      &.DialogGenius-bg {
        background: url("@/assets/images/AIchat/Banner/DialogGenius/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ChatterMinds-bg {
        background: url("@/assets/images/AIchat/Banner/ChatterMinds/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ConvoCraft-bg {
        background: url("@/assets/images/AIchat/Banner/ConvoCraft/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ChatMind-bg {
        background: url("@/assets/images/AIchat/Banner/ChatMind/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.AIConvo-bg {
        background: url("@/assets/images/AIchat/Banner/AIConvo/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      
      .banner-box {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1080px;
        margin: 0 auto;
        padding: 150px 0;
        &.rowReverse{
          flex-direction: row-reverse;
        }
        .banner-left {
          width: 100%;
          text-align: center;
          height: auto;
          cursor: default;
  
          .title {
            margin-bottom: 30px;
            font-size: 60px;
            font-weight: 900;
            line-height: 1.1;
            color: var(--theme-AIChat-banner-title-color);
          }
          .des {
            font-size: 24px;
            width: 70%;
            margin: 0 auto;
            color: var(--theme-AIChat-banner-des-color);
          }
          .btn {
            width: 400px;
            padding: 20px 0;
            text-align: center;
            color: var(--theme-AIChat-banner-btn-color);
            font-size: 30px;
            font-weight: bold;
            margin: 60px auto 0;
            background: var(--theme-AIChat-banner-btn-bg);
            border-radius: 15px;
            cursor: pointer;
          }
        }
  
      }
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .banner-container {
      font-family: Arial, Arial;
      width: 100%;
      &.DialogGenius-bg {
        background: url("@/assets/images/AIchat/Banner/DialogGenius/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ChatterMinds-bg {
        background: url("@/assets/images/AIchat/Banner/ChatterMinds/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ConvoCraft-bg {
        background: url("@/assets/images/AIchat/Banner/ConvoCraft/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.ChatMind-bg {
        background: url("@/assets/images/AIchat/Banner/ChatMind/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      &.AIConvo-bg {
        background: url("@/assets/images/AIchat/Banner/AIConvo/bg.jpg") no-repeat top center;
        background-size: cover;
      }
      .banner-box {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 0;
        .banner-left {
          padding: 0 .5rem;
          margin: 0 auto;
          text-align: center;
  
          .title {
            margin: 0 0 .2rem;
            padding: 0 .2rem;
            font-size: 0.56rem;
            color: var(--theme-AIChat-banner-title-color);
            line-height: .72rem;
          }
  
          .des {
            margin: .4rem auto;
            font-size: .32rem;
            color: var(--theme-AIChat-banner-des-color);
          }
          .btn {
            width: 200px;
            padding: 15px 0;
            text-align: center;
            color: var(--theme-AIChat-banner-btn-color);
            font-size: 20px;
            font-weight: bold;
            background: var(--theme-AIChat-banner-btn-bg);
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
            border-radius: 30px;
            margin: .5rem auto;
            cursor: pointer;
          }

        }

      }
    }
  }
  </style>