export function $extractDomain(url) {
 var hostname;

 // 移除协议（如 http://, https://）
 if (url.indexOf("//") > -1) {
  hostname = url.split('/')[2];
 } else {
  hostname = url.split('/')[0];
 }

 // 移除端口号
 hostname = hostname.split(':')[0];

 // 移除查询字符串
 hostname = hostname.split('?')[0];

 // 找到"."字符并从最后一个点切割
 var parts = hostname.split('.').reverse();

 if (parts != null && parts.length > 1) {
  // country code TLD (ccTLD) (如: .uk, .es 等)
  if (parts[1].length == 2 && parts.length > 2) {
   return parts[2];
  } else {
   return parts[1];
  }
 }
 return url;
}

export function $payApiHost() {
 const host = window.location.host.replace(/^www\./, '');  // Remove 'www.' from the beginning of the host
 if (host.includes('localhost')) {
  return 'http://api-site.3dlivewall2022.com'
 }
 let newHost = `${window.location.protocol}//api-site.${host}`;
 return newHost;
}