import axios from "axios"
import { $extractDomain } from "@/utils/index"
import { VUE_APP_SITE_ENTRANCE } from "./config";

const request = axios.create({
	baseURL: window.location.origin, // prod
	withCredentials: false,
	timeout: 300000 // 30W 语音转文字慢
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
	window.source = axios.CancelToken.source();
	config.cancelToken = source.token
	const mainPart = $extractDomain(window.location.hostname)
	config.headers['website'] = mainPart == 'localhost' ? VUE_APP_SITE_ENTRANCE : mainPart;
	if (localStorage.getItem('user_token_key')) {
		config.headers[localStorage.getItem('user_token_key')] = localStorage.getItem('user_token_value') || '';
	}
	if (config.custom && config.custom.baseURL) {
		config.baseURL = config.custom.baseURL
	}
	if (config.custom && config.custom.headers) {
		Object.keys(config.custom.headers).forEach(key => {
			config.headers[key] = config.custom.headers[key]
		})
	}
	// 在发送请求之前做些什么
	return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
	// 对响应数据做点什么
	return response.data
}, function (error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});

export default request
