import request from '@/utils/axios'
import { $payApiHost } from "@/utils/index"


// 抠图移除背景接口
export function koutuUploadImg(data) {
  return request({
    url: '/api/v1/matting/coutout',
    method: 'POST',
    data,
    headers: {'Content-Type':'application/form-data'},
    custom: {
      baseURL: 'https://toolapi.shadowshadows.click'
    }
  })
}

// 登录
export function Login(data) {
  return request({
    url: '/v1/user/session/login',
    method: 'POST',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 注册
export function Register(data) {
  return request({
    url: '/v1/user/register',
    method: 'POST',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 注销登录
export function Logout(params) {
  return request({
    url: '/v1/user/session/logout',
    method: 'GET',
    params,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 获取用户信息
export function GetUserInfo(params) {
  return request({
    url: '/v1/user/info',
    method: 'GET',
    params,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 修改用户信息
export function SetUserInfo(data) {
  return request({
    url: '/v1/user/info',
    method: 'PUT',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 取消订阅
export function Unsubscribe(data = {}) {
  return request({
    url: '/v1/user/cancel/subscription',
    method: 'PUT',
    data,
    custom: {
      baseURL: $payApiHost()
    }
  })
}

// 获取页面信息
export function getPageInfo(params) {
  return request({
    url: 'api/page',
    method: 'GET',
    params,
    custom: {
      baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BASE_URL : '/', // prod
    }
  })
}

// 获取网站信息
export function getSiteInfo(params) {
  return request({
    url: 'api/site',
    method: 'GET',
    params,
    custom: {
      baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_BASE_URL : '/', // prod
    }
  })
}