<template>
  <div :class="['banner-container', content.classname]">
    <div class="banner-box">
      <div class="banner-top">
        <div class="banner-member" v-if="content.member">
          <img :src="$getFile(content.member)" alt="">
        </div>
        <div class="banner-text">
          <div class="banner-title">
            {{ content.title }}
          </div>
          <div class="banner-subtitle">
            {{ content.subtitle }}
          </div>
        </div>
      </div>
      <div class="banner-upload">
        <div class="banner-upload-btn">Upload an Image</div>
        <div class="banner-upload-max">or Drop an lmage (jpg/png/jpeg); Max Dimensions:5000*5000</div>
        <div class="banner-upload-tips">Current Limit: 5 images per month. Free images left: 6</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AICartoon_Banner_1',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  }
}
</script>


<style scoped lang="scss">
.banner-container {
  background: url("@/assets/images/ai_cartoon/banner/AIToonify/bg.png") no-repeat top center;

  .banner-title {
    color: var(--AICartoon-banner-title-color);
  }

  .banner-subtitle {
    color: var(--AICartoon-banner-subtitle-color);
  }

  .banner-upload {
    border: var(--AICartoon-banner-upload-border);
    background: var(--AICartoon-banner-upload-bg);
    box-shadow: var(--AICartoon-banner-upload-shadow);

    .banner-upload-btn {
      background: var(--AICartoon-banner-upload-btn-bg);
      box-shadow: var(--AICartoon-banner-upload-btn-shadow);
      color: var(--AICartoon-banner-upload-btn-color);
    }

    .banner-upload-max {
      color: var(--AICartoon-banner-upload-max-color);
    }

    .banner-upload-tips {
      color: var(--AICartoon-banner-upload-tips-color);
    }
  }
}

@media #{'only screen and (min-width: 992px)'} {
  .banner-container {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;

    .banner-box {

      max-width: 1080px;
      margin: 0 auto;
      padding: 50px 0 30px;

      .banner-top {
        display: grid;
        grid-template-columns: 50% 50%;

        .banner-member {
          border-radius: 28px;
          overflow: hidden;

          >img {
            width: 100%;
            max-width: 504px;
          }
        }

        .banner-text {
          .banner-title {
            font-size: 60px;
            font-family: Arial, Arial;
            font-weight: bold;
            line-height: 72px;
            margin-bottom: 30px;
          }

          .banner-subtitle {
            font-size: 24px;
            font-family: Arial, Arial;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }

      .banner-upload {
        margin-top: 48px;
        width: 100%;
        text-align: center;
        padding: 50px 0 14px;
        border-radius: 28px 28px 28px 28px;

        .banner-upload-btn {
          cursor: pointer;
          margin: 0 auto;
          width: 528px;
          margin-bottom: 24px;

          border-radius: 28px 28px 28px 28px;

          font-size: 32px;
          font-family: Arial, Arial;
          font-weight: 900;
          line-height: 90px;
        }

        .banner-upload-max {
          font-size: 20px;
          font-family: Arial, Arial;
          font-weight: 400;
          line-height: 30px;

          margin-bottom: 62px;
        }

        .banner-upload-tips {
          font-size: 18px;
          font-family: Arial, Arial;
          font-weight: 400;
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .banner-container {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;

    .banner-box {
      max-width: 1080px;
      margin: 0 auto;
      padding: .50rem 0 .30rem;
      margin: 0 5%;

      .banner-top {
        display: grid;
        grid-template-columns: 100%;

        .banner-member {
          width: 100%;
          text-align: center;

          >img {
            border-radius: 20px;
            overflow: hidden;
            width: 100%;
            max-width: 5.04rem;
          }
        }

        .banner-text {
          .banner-title {
            font-size: .60rem;
            font-family: Arial, Arial;
            font-weight: bold;
            line-height: .72rem;
            margin-bottom: .30rem;
            text-align: center;
          }

          .banner-subtitle {
            font-size: .24rem;
            font-family: Arial, Arial;
            font-weight: 400;
            line-height: .30rem;
            text-align: center;
          }
        }
      }

      .banner-upload {
        margin-top: .48rem;
        width: 100%;
        text-align: center;
        padding: .50rem 0 .14rem;
        border-radius: .28rem .28rem .28rem .28rem;

        .banner-upload-btn {
          cursor: pointer;
          margin: 0 auto;
          width: 5.28rem;
          margin-bottom: .24rem;

          border-radius: .28rem .28rem .28rem .28rem;

          font-size: .32rem;
          font-family: Arial, Arial;
          font-weight: 900;
          line-height: .90rem;
        }

        .banner-upload-max {
          font-size: .20rem;
          font-family: Arial, Arial;
          font-weight: 400;
          line-height: .30rem;

          margin-bottom: .62rem;
        }

        .banner-upload-tips {
          font-size: .18rem;
          font-family: Arial, Arial;
          font-weight: 400;
        }
      }
    }
  }
}
</style>