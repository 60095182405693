<template>
  <div>
    <div class="maks" v-loading="loading" v-if="loading"></div>
    <div v-for="(component, name) in components" :key="name" class="layout-items">
      <component :is="component.component" v-bind="component.props"></component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      components: {},
      loading: true
    };
  },
  methods: {
  },
  async created() {
    try {
      let host = this.$extractDomain(window.location.hostname)
      if (host == 'localhost') host = sessionStorage.getItem('SiteEntrance')
      let result = await this.$api.getPageInfo({ host, page: 'index' })
      // console.log(result,"here result>>>")
      if (result) this.loading = false
      if (result.data.contentStatus !== 1) {
        // 遍历配置，动态引入和注册组件，并传递props
        for (const [name, config] of Object.entries(result.data.content)) {
          this.$set(this.components, name, { component: name, props: config.props });
        }
      } else {
        console.log('暂无编写富文本展示逻辑 >>>')
      }
    } catch (error) {
      console.error("Failed to load component configuration:", error);
    }
  },
};
</script>
<style lang="scss">
.layout-items {
  // margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
