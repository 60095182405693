<template>
  <div class="wrap">
    <div class="inner">
      <div class="block-title">{{ content.title }}</div>
      <div class="price-list">
        <div v-for="(item, idx) of content.priceSetup" :class="['price-item', { one: idx === 0 }, { two: idx === 1 }]">
          <div class="price-tag-box">
            <div class="price-tag-text">{{ item.tag }}</div>
          </div>
          <div class="price-main">
            <div class="price-money"
              v-html="item.price.replace(/{money}/g, productList[idx].money).replace(/{useTime}/g, productList[idx].useTime)">
            </div>

            <ul class="price-desc-list">
              <li class="price-desc-item" v-for="line in item.desList" :key="line">
                * {{ line }}
              </li>
            </ul>
            <div class="pay-button">
              {{ item.buttonTxt }}
            </div>
          </div>
          <p class="tip" v-html="item.tip
            .replace(/{money}/g, productList[idx].money)
            .replace(/{price}/g, productList[idx].price)
            .replace(/{useTime}/g, productList[idx].useTime)">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
const currencyMap = {
  USD: '$',
  PHP: '₱',
  ZAR: 'R',
  GBP: '£',
  SGD: 'S$',
  NZD: 'NZ$',
  EUR: '€',
  INR: '₹',
  CAD: 'C$',
  AUD: 'AUD',
  IDR: 'Rp',
  MYR: 'RM',
  KRW: '₩',
  HKD: 'HK$',
  TWD: 'NT$',
  BRL: 'R$',
  THB: '฿',
}

const offerTypePrices = {
  0: 'price',
  1: 'tryoutPrice',
  2: 'firstDiscountPrice'
}

const durationUnitType = {
  1: 'hour',
  2: 'day',
  3: 'week',
  4: 'month',
  5: 'year',
  100: 'Lifetime',
}

//  填充价格套餐
const useFillPriceInfo = (item) => {
  item.money = item[offerTypePrices[item.offerType]]

  let unit = 'durationUnit'
  let duration = 'duration'
  if (item.offerType === 1) {
    unit = 'tryoutDurationUnit'
    duration = 'tryoutDuration'
  }
  const durationUnit = item[unit]
  const durationDate = item[duration]

  item.useTime = durationUnitType[durationUnit]
  if (durationDate === 100) {
    item.useTime = 'LifeTime'
  } else {
    item.useTime = durationDate + '-' + item.useTime
  }

  item.currencyUnit = currencyMap[item.currency]

  return item
}

import { getProductList, createOrder, payOrder } from '@/api/pay'
import { $extractDomain } from "@/utils/index"
export default {
  name: 'AICartoon_Price',
  props: {
    payUrl: {
      type: Object,
      required: true,
      default: () => { }
    },
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {
      loading: false,
      //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
      productList: [
        {
          money: '19.99',
          price: '39.99',
          useTime: '1-Month'
        },
        {
          money: '199.99',
          price: '299.99',
          useTime: '1-Year'
        },
      ]
    }
  },
  created() {
    const mainPart = $extractDomain(window.location.hostname)
    getProductList({ code: mainPart, extend: {} }).then(result => {
      if (result.result && result.result.length) {
        this.productList = result.result.map(useFillPriceInfo)
      }
    })
  },
  mounted() {
    if (this.$route.hash) {
      this.$nextTick(() => {
        document.querySelector(this.$route.hash) && document.querySelector(this.$route.hash).scrollIntoView()
      })
    }
  },
  methods: {
    async handlePay(idx) {
      if (!this.productList[idx].productsPriceList) {
        return this.nopay()
      }
      if (this.loading) return
      this.loading = true
      try {
        const order = await createOrder({
          productsId: this.productList[idx].id,
          productsPriceId: '0',
          successUrl: `${this.$origin(this.payUrl.successUrl || 'sub/payresult')}`, //  支付成功url
          cancelUrl: `${this.$origin(this.payUrl.cancelUrl || 'sub/payresult')}`, //  取消支付链接
          userExtend: {},
          transparentExtend: {},
          orderAdInfoExtend: {},
          marketingChannel: 'seo'
        })

        const result = await payOrder({
          orderId: order.obj.orderId,
          payExtend: {}
        })
        window.location.href = result.obj.redirectUrl
      } catch (e) {
        this.loading = false
        alert(e.msg || e)
      }
    },
    nopay() {
      alert('Please wait patiently for us to access the payment')
    }
  }
}
</script>


<style lang="scss" scoped>
.block-title {
  color: var(--AICartoon-price-title-color);
}

.price-item {
  box-shadow: var(--AICartoon-price-item-shadow);
  border: var(--AICartoon-price-item-border, 'none');

  .price-tag-box {
    color: var(--AICartoon-price-tag-suffix);
    background: var(--AICartoon-price-tag-bg);
  }

  .price-main {
    background: var(--AICartoon-price-main-bg);

    .price-money {
      color: var(--AICartoon-price-money-suffix);
    }

    .price-desc-item {
      color: var(--AICartoon-price-desc-suffix);

    }

    .pay-button {
      background: var(--AICartoon-price-pay-button-bg);
      color: var(--AICartoon-price-pay-button-suffix);
      box-shadow: var(--AICartoon-price-pay-button-shadow);
      border: var(--AICartoon-price-pay-button-border, 'none')
    }
  }

  .tip {
    color: var(--AICartoon-price-tip-suffix);
    background: var(--AICartoon-price-tip-bg);
  }


  &:hover {
    box-shadow: var(--AICartoon-price-item-shadow-hover);
    border: var(--AICartoon-price-item-border-hover);


    .price-tag-box {
      background: var(--AICartoon-price-tag-bg-hover);
      color: var(--AICartoon-price-tag-suffix-hover, --AICartoon-price-tag-suffix);
    }

    .price-main {
      background: var(--AICartoon-price-main-bg-hover);

      .price-money {
        color: var(--AICartoon-price-money-suffix-hover);
      }

      .price-desc-item {
        color: var(--AICartoon-price-desc-suffix-hover);
      }

      .pay-button {
        background: var(--AICartoon-price-pay-button-bg-hover, --AICartoon-price-pay-button-bg);
        color: var(--AICartoon-price-pay-button-suffix-hover, --AICartoon-price-pay-button-suffix);
        box-shadow: var(--AICartoon-price-pay-button-shadow-hover, --AICartoon-price-pay-button-shadow);
        border: var(--AICartoon-price-pay-button-border-hover, --AICartoon-price-pay-button-border)
      }
    }

    .tip {
      color: var(--AICartoon-price-tip-suffix-hover);
      background: var(--AICartoon-price-tip-bg-hover);
    }
  }
}

@media #{'only screen and (min-width: 992px)'} {
  .wrap {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;
    // background: var(--AICartoon-step-bg);

    .inner {
      max-width: 1080px;
      margin: 0 auto;
      padding: 130px 0;


      .block-title {
        font-size: 60px;
        font-weight: 900;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 32px;
      }


      .price-list {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 24px;


        .price-item {
          border-radius: 28px 28px 28px 28px;
          overflow: hidden;

          .price-tag-box {
            line-height: 108px;
            font-size: 30px;
            font-weight: 400;
            text-align: center;
          }

          .price-main {
            padding: 30px 30px;

            .price-money {
              text-align: center;
              font-size: 48px;
              font-weight: 900;
              line-height: 52px;
              margin-bottom: 30px;
            }

            .price-desc-list {
              margin-bottom: 50px;
              height: 400px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              .price-desc-item {
                font-size: 20px;
                font-family: Arial, Arial;
                font-weight: 400;
                line-height: 28px;
                list-style: none;
              }
            }

            .pay-button {
              width: 100%;
              margin-left: auto;
              margin-right: auto;
              cursor: pointer;
              font-size: 26px;
              line-height: 80px;
              font-family: Arial, Arial;
              font-weight: bold;
              text-align: center;
              border-radius: 28px 28px 28px 28px;
            }
          }

          .tip {
            font-size: 16px;
            font-family: Arial, Arial;
            font-weight: 400;
            padding: 20px 40px;
          }
        }
      }
    }
  }
}

@media #{'only screen and (max-width: 991px)'} {
  .wrap {
    overflow: hidden;
    font-family: Arial, Arial;
    width: 100%;
    // background: var(--AICartoon-step-bg);

    .inner {
      max-width: 1080px;
      margin: 0 auto;
      padding: 1.3rem 0;


      .block-title {
        font-size: .48rem;
        font-weight: 900;
        line-height: .72rem;
        text-align: center;
        margin-bottom: .32rem;
      }


      .price-list {
        display: grid;
        grid-template-columns: 100%;
        margin: 5%;

        .price-item {
          border-radius: .28rem .28rem .28rem .28rem;
          overflow: hidden;
          margin-bottom: 0.24rem;


          .price-tag-box {
            line-height: 1.08rem;
            font-size: .30rem;
            font-weight: 400;
            text-align: center;
          }

          .price-main {
            padding: .30rem .30rem;

            .price-money {
              text-align: center;
              font-size: .48rem;
              font-weight: 900;
              line-height: .52rem;
              margin-bottom: .30rem;
            }

            .price-desc-list {
              margin-bottom: .77rem;

              .price-desc-item {
                font-size: .24rem;
                font-family: Arial, Arial;
                font-weight: 400;
                line-height: .32rem;
                margin-top: .24rem;
                list-style: none;
              }
            }

            .pay-button {
              margin: 0 auto;
              cursor: pointer;
              font-size: .26rem;
              line-height: .80rem;
              font-family: Arial, Arial;
              font-weight: bold;
              text-align: center;
              border-radius: .28rem .28rem .28rem .28rem;
            }
          }

          .tip {
            font-size: .22rem;
            font-family: Arial, Arial;
            font-weight: 400;
            line-height: .28rem;
            padding: .20rem .40rem;
          }
        }
      }
    }
  }
}
</style>