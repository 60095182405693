import { AICartoonBigYellow } from "./AICartoon/BigYellow"
import { AICartoonBigYellow2 } from "./AICartoon/BigYellow2"
import { AICartoonBigViolet } from "./AICartoon/BigViolet"
import { AICartoonBigBrown } from "./AICartoon/BigBrown"
import { AICartoonBigCyan } from "./AICartoon/BigCyan"
import { AICartoonOrange } from "./AICartoon/Orange"
import { AICartoonQingSe } from "./AICartoon/OingSe"
import { AICartoonGreen } from "./AICartoon/Green"
import { AICartoonPurple } from "./AICartoon/Purple"
import { AICartoonTaohong } from "./AICartoon/Taohong"
import { AIChatQingSe } from "./AIChat/QingSe"
import { AIChatBlue } from "./AIChat/Blue"
import { AIChatGreen } from "./AIChat/Green"
import { AIChatLigthBlue } from "./AIChat/LightBlue"
import { AIChatViolet } from "./AIChat/Violet"
import { AIChatPurple } from "./AIChat/Purple"
import { AIChatOrange } from "./AIChat/Orange"
import { AIChatBigYellow } from "./AIChat/BigYellow"
import { AIChatOrange_SynthChat } from "./AIChat/Orange-SynthChat"



import { PDFawesomeRed } from "./PDF/AwesomeRed"
import { PDFblackCyan } from "./PDF/BlackCyan"

import { coustomPDFdarkPurpleTheme } from "./PDF/DarkPurple"
import { coustomPDFturquoiseTheme } from "./PDF/Turquoise"
import { coustomPDFgoldenYellowTheme } from "./PDF/GoldenYellow"
import { coustomPDFpalatinateBlueTheme } from "./PDF/PalatinateBlue"
import { coustomPDFblackCoffeeTheme } from "./PDF/BlackCoffee"
import { coustomAICartoonBrandeisBlueTheme } from "./AICartoon/BrandeisBlue"
import { coustomAICartoonBlackBlueTheme } from "./AICartoon/BlackBlue"
import { coustomAICartoonUltramarineBlueTheme } from "./AICartoon/UltramarineBlue"
import { coustomAICartoonDigiCartoonizerTheme } from "./AICartoon/DigiCartoonizer"
import { coustomAICartoonArtifyCartoonTheme } from "./AICartoon/ArtifyCartoon"


const defaultTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #36454F 0%, #5C6B77 100%)",
  // discuss-ppt 背景色
  "--theme-primary-ppt-wrap": "linear-gradient(315deg, #36454F 0%, #5C6B77 100%)",
  //默认黑
  "--theme-primary-black": "#000000",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  //主题亮色
  "--theme-primary-suffix": "#3f4e59",
  //价格 tag
  "--theme-primary-price-tag": "#3f4e59",
  "--theme-primary-price-tag-bg": "#e9edf8",
  //标签
  "--theme-label": "linear-gradient(115deg, #FF7373 0%, #FD9A5E 100%)",
  //tiny 类
  "--theme-primary-tiny": "#7c7c7c",
  //footer 背景色
  "--theme-primary-footer-bg": "#3f4e59",
  //输入框聚焦
  "--theme-focus": "#419eff",
  //输入框错误
  "--theme-focus-err": "#ea3042"
}



// 自定义绿色
const customGreenTheme = {
  //主题背景颜色
  "--theme-primary": "#1BDA7C",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "white",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "white",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#1BDA7C",
  // //主题深副色
  // "--theme-primary-dark-sub": "#E4430F",
  // //主题浅副色
  // "--theme-primary-light-sub": "#FF8761",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#01002C",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...AIChatGreen,
  ...AICartoonGreen

}


// 自定义AI卡通大黄色
const coustomAICartoonBigYellowTheme = {
  //主题背景颜色
  "--theme-primary": "#FFE000",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#FFE000",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "#212121",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#212121",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  ...AICartoonBigYellow2

}
// 自定义大黄色
const customBigYellowTheme = {
  //主题背景颜色
  "--theme-primary": "#FFE000",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#FFE000",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "#212121",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#212121",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...AICartoonBigYellow,
  ...AIChatBigYellow

}

const customBigVioletTheme = {
  //主题背景颜色
  "--theme-primary": "#7942FE",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#7942FE",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "#FFFFFF",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#212121",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...AICartoonBigViolet,
}

const customAICartoonBigCyanTheme = {
  //主题背景颜色
  "--theme-primary": "#77E4FF",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#77E4FF",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "#fff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#212121",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...AICartoonBigCyan,
}

const customAICartoonBrownTheme = {
  //主题背景颜色
  "--theme-primary": "#4C3626",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#FFF",
  // 页头 登录 字体颜色
  "--theme-primary-login-btn-suffix": "#FFFFFF",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#212121",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...AICartoonBigBrown,
}

// 自定义棕色
const customBrownTheme = {
  //主题背景颜色
  "--theme-primary": "#4C3626",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#4C3626",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置
  // koutu
  "--theme-primary-koutu-upload-bg": "#F6F6F6",
  "--theme-koutu-price-btn": "#FFCF52",
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "white",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "linear-gradient(315deg, #FF612F 0%, #FF8761 100%)",

  // PDF
  "--theme-pdf-quesions-bg": "white",
  "--theme-pdf-quesions-inner-bg": "#F2F4F3",
  "--theme-pdf-price-color": "#FF612F",
  "--theme-pdf-price-btn": "#FF612F",
  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white"
}

const coustomPDFgreenTheme = {
  //主题背景颜色
  "--theme-primary": "#1BDA7C",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#1BDA7C",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置

  // prcie
  "--theme-pdf-price-tag-color-hover": "#252325",
  "--theme-pdf-price-tips-color-hover": "#252325",
  "--theme-pdf-price-tips-bg-hover": 'white',
  "--theme-pdf-price-btn-hover": 'white',
  "--theme-pdf-price-inner-bg-hover": "#1BDA7C",

  // comment
  "--theme-pdf-comment-item-bg-hover": "#1BDA7C",
  // PDF
  "--theme-pdf-questions-title-color": "#fff",
  "--theme-pdf-quesions-bg": "#1BDA7C",
  "--theme-pdf-quesions-inner-bg": "#fff",
  "--theme-pdf-price-color": "#FCC133",
  "--theme-pdf-price-btn": "#1BDA7C",
  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white"
}

const coustomPDFvioletTheme = {
  //主题背景颜色
  "--theme-primary": "#7942FE",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#7942FE",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置

  // PDF
  // PDF quetions
  "--theme-pdf-questions-title-color": "#fff",
  "--theme-pdf-quesions-bg": "#7942FE",
  "--theme-pdf-quesions-inner-bg": "#fff",

  "--theme-pdf-quesions-item-bg": "#7942FE",
  "--theme-pdf-quesions-item-title-color": "#fff",
  "--theme-pdf-quesions-item-title-color-hover": "#fff",

  "--theme-pdf-quesions-item-des-color": "rgba(255,255,255,0.7)",

  "--theme-pdf-comment-item-bg-hover": "#7942FE",

  // pdf价格
  "--theme-pdf-price-tag-bg": "#7942FE",
  "--theme-pdf-price-tag-bg-hover": "",
  "--theme-pdf-price-tag-color": "#fff",

  "--theme-pdf-price-tips-bg": "#fff",
  "--theme-pdf-price-tips-bg-hover": "",

  "--theme-pdf-price-color": "#FC7D33",
  "--theme-pdf-price-inner-bg-hover": "linear-gradient(to right, #414DF7, #863DFE)",

  "--theme-pdf-price-btn": "#7942FE",
  "--theme-pdf-price-btn-hover": "#fff",

  "--theme-pdf-price-btn-color": "#fff",
  "--theme-pdf-price-btn-color-hover": "#7942FE",


  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white"
}

const coustomPDFawesomeRedTheme = {
  //主题背景颜色
  "--theme-primary": "#FD2C52",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#FD2C52",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#FD2C52",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置

  ...PDFawesomeRed,
}

const coustomPDFblackCyanTheme = {
  //主题背景颜色
  "--theme-primary": "#77E4FF",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#77E4FF",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#111112",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#77E4FF",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置

  ...PDFblackCyan,
}

const coustomPDFbigDeepBlueTheme = {
  //主题背景颜色
  "--theme-primary": "#034EF3",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#034EF3",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置

  // PDF
  // PDF quetions
  "--theme-pdf-questions-title-color": "#fff",
  "--theme-pdf-quesions-bg": "#034EF3",
  "--theme-pdf-quesions-inner-bg": "#fff",

  "--theme-pdf-quesions-item-bg": "#034EF3",
  "--theme-pdf-quesions-item-title-color": "#fff",
  "--theme-pdf-quesions-item-title-color-hover": "#fff",

  "--theme-pdf-quesions-item-des-color": "rgba(255,255,255,0.7)",

  // comment
  "--theme-pdf-comment-item-bg-hover": "#034EF3",
  
  // pdf价格
  "--theme-pdf-price-tag-bg": "#034EF3",
  "--theme-pdf-price-tag-bg-hover": "#FF9B52",
  "--theme-pdf-price-tag-color": "#fff",

  "--theme-pdf-price-li-color": "#111112",
  "--theme-pdf-price-li-color-hover": "#111112",

  "--theme-pdf-price-tips-color": "#7D7D7F",
  "--theme-pdf-price-tips-color-hover": "#7D7D7F",
  "--theme-pdf-price-tips-bg": "#fff",
  "--theme-pdf-price-tips-bg-hover": "",

  "--theme-pdf-price-color": "#034EF3",
  "--theme-pdf-price-color-hover": "#FF9B52",

  "--theme-pdf-price-inner-bg-hover": "",

  "--theme-pdf-price-btn": "#034EF3",
  "--theme-pdf-price-btn-hover": "#FF9B52",

  "--theme-pdf-price-btn-color": "#fff",
  "--theme-pdf-price-btn-color-hover": "#fff",


  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white"
}


// 自定义SynthChat AI-chat 产品橙色
const customOrange_SynthChatTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #FF612F 0%, #FF8761 100%)",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#FF612F",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...AIChatOrange_SynthChat
}

// 自定义橙色
const customOrangeTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #FF612F 0%, #FF8761 100%)",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#FF612F",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#FF612F",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置
  // koutu
  "--theme-primary-koutu-upload-bg": "#F6F6F6",
  "--theme-koutu-price-btn": "#FFCF52",
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "#252325",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "linear-gradient(315deg, #FF612F 0%, #FF8761 100%)",

  // PDF
  "--theme-pdf-quesions-bg": "white",
  "--theme-pdf-quesions-inner-bg": "#F2F4F3",
  "--theme-pdf-price-color": "#252325",
  "--theme-pdf-price-btn": "#FFC633",
  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white",

  ...AIChatOrange,
  ...AICartoonOrange
}

// 自定义浅蓝色
const customLightBlueTheme = {
  //主题背景颜色
  "--theme-primary": "#28D1FB",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#28D1FB",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#C4E7F0",
  //价格 tag
  "--theme-primary-price-tag": "#28D1FB",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置
  // koutu
  "--theme-primary-koutu-upload-bg": "#F6F6F6",
  "--theme-koutu-price-btn": "#FFCF52",
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "white",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "linear-gradient(315deg, #FF612F 0%, #FF8761 100%)",

  // PDF
  "--theme-pdf-quesions-bg": "white",
  "--theme-pdf-quesions-inner-bg": "#F2F4F3",
  "--theme-pdf-price-color": "#FF612F",
  "--theme-pdf-price-btn": "#101112",
  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white",

  ...AIChatLigthBlue
}


// 自定义紫罗兰色
const customVioletTheme = {
  //主题背景颜色
  "--theme-primary": "#463699",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#463699",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#5f5595",
  //价格 tag
  "--theme-primary-price-tag": "#463699",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置
  // koutu
  "--theme-primary-koutu-upload-bg": "#F6F6F6",
  "--theme-koutu-price-btn": "#FFCF52",
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "white",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "#FF4867",

  // PDF
  "--theme-pdf-quesions-bg": "white",
  "--theme-pdf-quesions-inner-bg": "#F2F4F3",
  "--theme-pdf-price-color": "#252325",
  "--theme-pdf-price-btn": "#FFC633",
  "--theme-pdf-explain-inner-bg": "white",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white",

  ...AIChatViolet,
  ...AICartoonPurple
}




// 自定义深紫色
const customPurpleTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #434DF8 0%, #7942FE 100%)",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#7942FE",
  //主题浅副色
  "--theme-primary-light-sub": "#A783FF",
  //价格 tag
  "--theme-primary-price-tag": "#7942FE",
  "--theme-primary-price-tag-bg": "#FF8D1A",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#252325",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  // koutu
  "--theme-primary-koutu-upload-bg": "linear-gradient(134deg, #FDFCFE 0%, #F7F2FF 100%)",
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "#252325",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "linear-gradient(315deg, #434DF8 0%, #7942FE 100%)",

  // price
  "--theme-koutu-price-btn": "linear-gradient(128deg, #FB7838 0%, #FF8D1B 100%)",
  "--theme-koutu-price-btn-shadow": "#D13200",

  ...AIChatPurple
}



// 自定义深黑色
const customBlackTheme = {
  //主题背景颜色
  "--theme-primary": "#01002C",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#01002C",
  //主题浅副色
  "--theme-primary-light-sub": "#403F55",
  //价格 tag
  "--theme-primary-price-tag": "#01002C",
  "--theme-primary-price-tag-bg": "#FF8D1A",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#252325",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置

  //koutu
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "#252325",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "#01002C",

  "--theme-primary-koutu-upload-bg": "#FAF6F4",
  "--theme-koutu-price-btn": "linear-gradient(128deg, #FB7838 0%, #FF8D1B 100%)",
  "--theme-koutu-price-btn-shadow": "#D13200"
}

// 自定义青色
const customQingseTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(132deg, #0195C3 0%, #00CAA8 100%)",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#26D7B9",
  //主题深副色
  "--theme-primary-dark-sub": "#17A18A",
  //主题浅副色
  "--theme-primary-light-sub": "#88D7C9",
  //价格 tag
  "--theme-primary-price-tag": "#463699",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#252325",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",


  //koutu
  "--theme-koutu-banner-bg": "white",
  "--theme-koutu-banner-color": "#252325",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "#01002C",

  "--theme-primary-koutu-upload-bg": "#FAF6F4",
  "--theme-koutu-price-btn": "linear-gradient(128deg, #FB7838 0%, #FF8D1B 100%)",
  "--theme-koutu-price-btn-shadow": "#D13200",

  ...AIChatQingSe,
  ...AICartoonQingSe


}

// 自定义天蓝色
const customBlueTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #0055FF 0%, #3377ff 100%)",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#0055FF",
  //主题深副色
  "--theme-primary-dark-sub": "#0042D2",
  //主题浅副色
  "--theme-primary-light-sub": "#296CFF",
  //价格 tag
  "--theme-primary-price-tag": "#0055FF",
  "--theme-primary-price-tag-bg": "#FFC633",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#252325",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  // 模块配置
  // koutu
  "--theme-primary-koutu-upload-bg": "#F6F6F6",
  "--theme-koutu-price-btn": "#FF9B52",
  "--theme-koutu-banner-bg": "linear-gradient(315deg, #0055FF 0%, #3377ff 100%)",
  "--theme-koutu-banner-color": "white",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "#252325",
  // PDF
  "--theme-pdf-quesions-bg": "#F2F4F3",
  "--theme-pdf-quesions-inner-bg": "white",
  "--theme-pdf-price-color": "#252325",
  "--theme-pdf-price-btn": "#FFC633",
  "--theme-pdf-explain-inner-bg": "#F2F4F3",
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white",

  ...AIChatBlue
}

// 自定义桃红色
const customTaohongTheme = {
  //主题背景颜色
  "--theme-primary": "linear-gradient(315deg, #FD2C52 0%, #EF4B68 100%)",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#ffffff",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#ffffff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#FD2C52",
  //主题深副色
  "--theme-primary-dark-sub": "#EC0D35",
  //主题浅副色
  "--theme-primary-light-sub": "#EF4B68",
  //价格 tag
  "--theme-primary-price-tag": "#0055FF",
  "--theme-primary-price-tag-bg": "#FFC633",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#252325",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",
  // 模块配置
  "--theme-primary-koutu-upload-bg": "#F6F6F6",
  "--theme-koutu-price-btn": "#FF9B52",
  "--theme-koutu-banner-bg": "linear-gradient(315deg, #FD2C52 0%, #EF4B68 100%)",
  "--theme-koutu-banner-color": "white",
  "--theme-koutu-banner-btn": "white",
  "--theme-koutu-banner-btn-bg": "#252325",

  ...AICartoonTaohong

}

export const theme = {
  // 默认碳灰色
  defaultTheme,
  // 天蓝色 -PDF 抠图 AIChat
  customBlueTheme,
  // 绿色 - AIChat
  customGreenTheme,
  // 深紫色 - 抠图
  customPurpleTheme,
  // 橙色 - 抠图 PDF AIchat
  customOrangeTheme,
  // 橙色 - SynthChat/AI-chat 
  customOrange_SynthChatTheme,
  // 桃红色 - 抠图
  customTaohongTheme,
  // 深黑色 - 抠图
  customBlackTheme,
  // 棕色   - PDF
  customBrownTheme,
  // 紫罗兰色 - PDF AIchat
  customVioletTheme,
  // 浅蓝色 - PDF AIChat
  customLightBlueTheme,
  // 青色 - AIchat
  customQingseTheme,
  // 绿色   - PDF
  coustomPDFgreenTheme,
  // 紫罗兰 - PDF
  coustomPDFvioletTheme,
  // 大深蓝色 - PDF
  coustomPDFbigDeepBlueTheme,


  // 品红色 - PDF
  coustomPDFawesomeRedTheme,
  // 黑青色 - PDF
  coustomPDFblackCyanTheme,
  // 深紫色 - PDF
  coustomPDFdarkPurpleTheme,
  // 绿松石色 - PDF
  coustomPDFturquoiseTheme,
  // 金黄色   - PDF
  coustomPDFgoldenYellowTheme,
  // 普法尔茨蓝 - PDF
  coustomPDFpalatinateBlueTheme,
  // 黑咖啡 - PDF
  coustomPDFblackCoffeeTheme,

  // 大黄色 - AI卡通
  customBigYellowTheme,
  // 布兰代斯蓝 - AI卡通
  coustomAICartoonBrandeisBlueTheme,
  // 黑蓝 - AI卡通
  coustomAICartoonBlackBlueTheme,
  // 紫罗兰 - AI卡通
  customBigVioletTheme,
  // 棕色   - AI卡通
  customAICartoonBrownTheme,
  // 青色   - AI卡通
  customAICartoonBigCyanTheme,
  // 群青 - AI卡通
  coustomAICartoonUltramarineBlueTheme,
  coustomAICartoonDigiCartoonizerTheme,
  coustomAICartoonArtifyCartoonTheme,
  coustomAICartoonBigYellowTheme,
}