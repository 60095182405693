const Banner = {
  "--AICartoon-banner-title-color": "#252325",
  "--AICartoon-banner-subtitle-color": "#4D4D4D",

  "--AICartoon-banner-upload-bg": "#fff",
  "--AICartoon-banner-upload-shadow": "0px 10px 0px 0px #FFE000",

  "--AICartoon-banner-upload-btn-color": "#212121",
  "--AICartoon-banner-upload-btn-shadow": "0px 10px 0px 0px #212121",
  "--AICartoon-banner-upload-btn-bg": "#FFE000",

  "--AICartoon-banner-upload-max-color": "#808080",

  "--AICartoon-banner-upload-tips-color": "#B3B3B3",
}

const Price = {
  "--AICartoon-price-title-color": "#252325",


  "--AICartoon-price-item-shadow": "none",
  "--AICartoon-price-item-shadow-hover": "none",
  "--AICartoon-price-item-border": "none",
  "--AICartoon-price-item-border-hover": "none",

  // 价格顶部
  "--AICartoon-price-tag-bg": "#FFF7CE",
  "--AICartoon-price-tag-bg-hover": "#FFF7CE",
  "--AICartoon-price-tag-suffix": "#252325",
  // 详情模块颜色
  "--AICartoon-price-main-bg": "#FAF6E1",
  "--AICartoon-price-main-bg-hover": "#FAF6E1",
  // 价格颜色
  "--AICartoon-price-money-suffix": "#101012",
  "--AICartoon-price-money-suffix-hover": "#101012",
  // 套餐详情颜色
  "--AICartoon-price-desc-suffix": "#101012",
  "--AICartoon-price-desc-suffix-hover": "#101012",
  // 购买按钮
  "--AICartoon-price-pay-button-suffix": "#101012",
  "--AICartoon-price-pay-button-bg": "#FFE000",
  "--AICartoon-price-pay-button-bg-hover": "#FFE000",
  "--AICartoon-price-pay-button-shadow": "0px 8px 0px 0px #212121",
  // 套餐 tips
  "--AICartoon-price-tip-suffix": "#252325",
  "--AICartoon-price-tip-bg": "#FFEF9F",
  "--AICartoon-price-tip-suffix-hover": "#252325",
  "--AICartoon-price-tip-bg-hover": "#FFEF9F",
}

const Questions = {
  "--AICartoon-questions-title-color": "#252325",

  "--AICartoon-questions-item-bg": "#FAF9FC",
  "--AICartoon-questions-item-bg-open": "#FAF9FC",

  "--AICartoon-questions-item-boxshadow": "none",
  "--AICartoon-questions-item-boxshadow-open": "0px 10px 0px 0px #212121",

  "--AICartoon-questions-item-border": "none",
  "--AICartoon-questions-item-border-open": "none",

  "--AICartoon-questions-item-number-suffix": "#FF6B30",

  "--AICartoon-questions-item-suffix": "#252325",
  "--AICartoon-questions-item-suffix-open": "#252325",

  "--AICartoon-questions-item-des-suffix": '#666166',
}

const Comment = {
  "--AICartoon-comment-title-color": "#252525",

  "--AICartoon-comment-bg": "linear-gradient(180deg, #FFF8C1 0%, #F0FFDB 100%)",

  "--AICartoon-comment-item-bg": "#FFFFFF",
  "--AICartoon-comment-item-bg-hover": "#FFE000",

  "--AICartoon-comment-item-border": "none",
  "--AICartoon-comment-item-border-hover": "none",

  "--AICartoon-comment-item-boxshadow": "0px 10px 0px 0px #212121",
  "--AICartoon-comment-item-boxshadow-hover": "0px 10px 0px 0px #212121",

  "--AICartoon-comment-item-subtitle-suffix": "#252325",
  "--AICartoon-comment-item-subtitle-suffix-hover": "#252325",

  "--AICartoon-comment-item-name-suffix": "#4C484C",
  "--AICartoon-comment-item-name-suffix-hover": "#252325",

  "--AICartoon-comment-item-city-suffix": "#4C484C",
  "--AICartoon-comment-item-city-suffix-hover": "#252325",
}

const Case = {
  "--AICartoon-title-color": "#252525",

  "--AICartoon-case-bg": "#FAF9FC",
  "--AICartoon-case-item-bg": "#fff",

  "--AICartoon-case-item-title-color": "#252525",
  "--AICartoon-case-item-title-color-hover": "#252525",
  "--AICartoon-case-item-desc-color": "#4C484C",
  "--AICartoon-case-item-desc-color-hover": "#4C484C",

  "--AICartoon-case-item-shadow": "0px 10px 0px 0px #FFE000",
  "--AICartoon-case-item-shadow-hover": "0px 10px 0px 0px #212121",
  "--AICartoon-case-item-border": "none",
  "--AICartoon-case-item-border-hover": "none",
}

const Step = {
  "--AICartoon-step-bg": 'linear-gradient(180deg, #FFF8C1 0%, #F0FFDB 100%)',
  "--AICartoon-step-item-bg": "#fff",

  "--AICartoon-step-item-bg-hover": "#FFE000",
  "--AICartoon-step-item-shadow": "0px 10px 0px 0px #212121",
  "--AICartoon-step-item-shadow-hover": "0px 10px 0px 0px #212121",
  "--AICartoon-step-item-border": "none",
  "--AICartoon-step-item-border-hover": "none",
}


export const AICartoonBigYellow = {
  ...Banner,
  ...Price,
  ...Questions,
  ...Comment,
  ...Case,
  ...Step
}