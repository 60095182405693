const Price = {
  // pdf价格
  "--theme-pdf-price-tag-bg": "",
  "--theme-pdf-price-tag-bg-hover": "",
  "--theme-pdf-price-tag-color": "#111112",
  "--theme-pdf-price-tag-color-hover": "#FFFFFF",

  "--theme-pdf-price-li-color": "#111112",
  "--theme-pdf-price-li-color-hover": "#FFFFFF",

  "--theme-pdf-price-tips-color": "#034EF3",
  "--theme-pdf-price-tips-color-hover": "#FFFFFF",
  "--theme-pdf-price-tips-bg": "rgba(2, 112, 249, 0.2)",
  "--theme-pdf-price-tips-bg-hover": "#0270F9",

  "--theme-pdf-price-color": "",
  "--theme-pdf-price-color-hover": "",

  "--theme-pdf-price-inner-bg": "rgba(2, 112, 249, 0.1)",
  "--theme-pdf-price-inner-bg-hover": "#034EF3",

  "--theme-pdf-price-btn": "#034EF3",
  "--theme-pdf-price-btn-hover": "#fff",

  "--theme-pdf-price-btn-color": "#fff",
  "--theme-pdf-price-btn-color-hover": "#034EF3",
}

const Questions = {
  // PDF
  // PDF quetions
  "--theme-pdf-questions-title-color": "#111112",
  "--theme-pdf-quesions-bg": "#F2F4F3",
  "--theme-pdf-quesions-inner-bg": "#FFFFFF",

  "--theme-pdf-quesions-item-bg": "#FFFFFF",
  "--theme-pdf-quesions-item-title-color": "#111112",
  "--theme-pdf-quesions-item-title-color-hover": "",

  "--theme-pdf-question-icon-filter": "",

  "--theme-pdf-quesions-item-des-color": "#7D7D7F",
}


const Steps = {
  "--theme-pdf-step-bg": "#F2F4F3",
  "--theme-pdf-step-subtitle-color": "#111112",
  "--theme-pdf-step-inner-bg": "#fff"
}

const Explain = {
  "--theme-pdf-explain-inner-bg": "white",
}


const Comment = {
  "--theme-pdf-comment-item-bg": "#F2F4F3",
  "--theme-pdf-comment-city-color": "#7D7D7F",
  "--theme-pdf-comment-name-color": "#111112",
  "--theme-pdf-comment-subtitle-color": "#111112",

  "--theme-pdf-comment-item-bg-hover": "#034EF3",
  "--theme-pdf-comment-city-color-hover": "#FFF",
  "--theme-pdf-comment-name-color-hover": "#FFF",
  "--theme-pdf-comment-subtitle-color-hover": "#FFF",
}

export const coustomPDFpalatinateBlueTheme = {
  //主题背景颜色
  "--theme-primary": "#034EF3",
  //默认黑
  "--theme-primary-black": "#252325",
  //默认白
  "--theme-primary-white": "#ffffff",
  // 页脚 logo 字体颜色
  "--theme-primary-footer-logo-suffix": "#034EF3",
  // 登录按钮 字体颜色
  "--theme-primary-login-btn-suffix": "#fff",
  // 不透明度70%
  "--theme-primary-white-70": "#b2b2b2",
  //主题亮色
  "--theme-primary-suffix": "#034EF3",
  //主题深副色
  "--theme-primary-dark-sub": "#E4430F",
  //主题浅副色
  "--theme-primary-light-sub": "#FF8761",
  //价格 tag
  "--theme-primary-price-tag": "#4C3626",
  "--theme-primary-price-tag-bg": "#FFCF52",
  //tiny 类
  "--theme-primary-tiny": "#666166",
  //footer 背景色
  "--theme-primary-footer-bg": "#000000",
  //输入框聚焦
  "--theme-focus": "#3377ff",
  //输入框错误
  "--theme-focus-err": "#ea3042",

  ...Price,
  ...Questions,
  ...Steps,
  ...Explain,
  ...Comment
}