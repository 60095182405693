const Banner = {
  "--AICartoon-banner-title-color": "#FFFFFF",
  "--AICartoon-banner-subtitle-color": "#fff",

  "--AICartoon-banner-upload-bg": "#FFFFFF",
  "--AICartoon-banner-upload-shadow": "8px solid #FFFFFF",

  "--AICartoon-banner-upload-btn-color": "#FFFFFF",
  "--AICartoon-banner-upload-btn-shadow": "",
  "--AICartoon-banner-upload-btn-bg": "#FF612F",

  "--AICartoon-banner-upload-max-color": "#808080",

  "--AICartoon-banner-upload-tips-color": "#B3B3B3",
}


const Price = {
  "--AICartoon-price-title-color": "#252325",

  "--AICartoon-price-item-shadow": "none",
  "--AICartoon-price-item-shadow-hover": "none",
  "--AICartoon-price-item-border": "none",
  "--AICartoon-price-item-border-hover": "none",

  // 价格顶部
  "--AICartoon-price-tag-bg": "#F2F4F3",
  "--AICartoon-price-tag-bg-hover": "#4C3626",
  "--AICartoon-price-tag-suffix": "#252325",
  "--AICartoon-price-tag-suffix-hover": "#fff",
  // 详情模块颜色
  "--AICartoon-price-main-bg": "#F2F4F3",
  "--AICartoon-price-main-bg-hover": "#4C3626",
  // 价格颜色
  "--AICartoon-price-money-suffix": "#101012",
  "--AICartoon-price-money-suffix-hover": "#FFFFFF",
  // 套餐详情颜色
  "--AICartoon-price-desc-suffix": "#101012",
  "--AICartoon-price-desc-suffix-hover": "#FFFFFF",
  // 购买按钮
  "--AICartoon-price-pay-button-suffix": "#FFF",
  "--AICartoon-price-pay-button-bg": "#FF8C19",
  "--AICartoon-price-pay-button-shadow": "none",
  "--AICartoon-price-pay-button-bg-hover": "#FF8C19",
  // 套餐 tips
  "--AICartoon-price-tip-suffix": "#252325",
  "--AICartoon-price-tip-suffix-hover": "#FFFFFF",
  "--AICartoon-price-tip-bg": "#F2F4F3",
  "--AICartoon-price-tip-bg-hover": "#4C3626",

}

const Questions = {
  "--AICartoon-questions-title-color": "#252325",

  "--AICartoon-questions-item-bg": "#F2F4F3",
  "--AICartoon-questions-item-bg-open": "#FFF",

  "--AICartoon-questions-item-boxshadow": "none",
  "--AICartoon-questions-item-boxshadow-open": "0px 20px 50px 0px rgba(0,0,0,0.15)",

  "--AICartoon-questions-item-border": "none",
  "--AICartoon-questions-item-border-open": "none",

  "--AICartoon-questions-item-number-suffix": "#4C3626",

  "--AICartoon-questions-item-suffix": "#101012",
  "--AICartoon-questions-item-suffix-open": "#101012",

  "--AICartoon-questions-item-des-suffix": '#252325',
}

const Comment = {
  "--AICartoon-comment-title-color": "#252325",

  "--AICartoon-comment-bg": "#fff",

  "--AICartoon-comment-item-bg": "#F2F4F3",
  "--AICartoon-comment-item-bg-hover": "#FFE000",

  "--AICartoon-comment-item-border": "none",
  "--AICartoon-comment-item-border-hover": "none",

  "--AICartoon-comment-item-boxshadow": "none",
  "--AICartoon-comment-item-boxshadow-hover": "none",

  "--AICartoon-comment-item-subtitle-suffix": "#252325",
  "--AICartoon-comment-item-subtitle-suffix-hover": "#fff",

  "--AICartoon-comment-item-name-suffix": "#4C484C",
  "--AICartoon-comment-item-name-suffix-hover": "#fff",

  "--AICartoon-comment-item-city-suffix": "#999199",
  "--AICartoon-comment-item-city-suffix-hover": "#fff",
}

const Case = {
  "--AICartoon-case-title-color": "#252325",

  "--AICartoon-case-bg": "#fff",
  "--AICartoon-case-item-bg": "#fff",
  "--AICartoon-case-item-bg-hover": "#fff",

  "--AICartoon-case-item-title-color": "#252525",
  "--AICartoon-case-item-title-color-hover": "#252525",
  "--AICartoon-case-item-desc-color": "#4C484C",
  "--AICartoon-case-item-desc-color-hover": "#4C484C",

  "--AICartoon-case-item-shadow": "0px 20px 20px 0px rgba(0,0,0,0.15)",
  "--AICartoon-case-item-shadow-hover": "0px 20px 20px 0px rgba(0,0,0,0.15)",
  "--AICartoon-case-item-border": "none",
  "--AICartoon-case-item-border-hover": "none",
}

const Step = {
  "--AICartoon-step-title-color": "#121212",

  "--AICartoon-step-bg": '#F2F4F3',
  "--AICartoon-step-item-bg": "#fff",
  "--AICartoon-step-item-bg-hover": "#fff",
  "--AICartoon-step-item-shadow": "none",
  "--AICartoon-step-item-shadow-hover": "0px 20px 50px 0px rgba(0,0,0,0.15)",
  "--AICartoon-step-item-border": "none",
  "--AICartoon-step-item-border-hover": "none",
}


export const AICartoonBigBrown = {
  ...Banner,
  ...Price,
  ...Questions,
  ...Comment,
  ...Case,
  ...Step
}