export const AIChatBigYellow = {
    // 模块配置
    // AIChat - banner
    "--theme-AIChat-banner-title-color": "#FFE000",
    "--theme-AIChat-banner-des-color": "white",
    "--theme-AIChat-banner-btn-color": "#101112",
    "--theme-AIChat-banner-btn-bg": "#FFE000",
    // AIChat - explain
    "--theme-AIChat-explain-bg": "white",
    "--theme-AIChat-explain-inner-bg": "#F2F2F2",
    "--theme-AIChat-explain-item-shadow": "none",
    "--theme-AIChat-explain-inner-bg-hover": "#17A18A",
    // AIChat - step2
    "--theme-AIChat-step2-bg": "#F6F6F6",
    "--theme-AIChat-step2-inner-bg": "white",
    "--theme-AIChat-step2-inner-bg-hover": "#FFE000",
    "--theme-AIChat-step2-title-color": "white",
    "--theme-AIChat-step2-inner-title-color": "#252325",
    "--theme-AIChat-step2-inner-desc-color": "#252325",
    "--theme-AIChat-step2-inner-title-color-hover": "#252325",
    "--theme-AIChat-step2-inner-desc-color-hover": "#252325",
    "--theme-AIChat-step2-radius": "15px",
    // AIChat - price
    "--theme-AIChat-price-btn-bg": "#FFE000",
    "--theme-AIChat-price-btn-bg-hover": "#FFE000",
    "--theme-AIChat-price-btn-color": "#111212",
    "--theme-AIChat-price-btn-color-hover": "#111212",
    "--theme-AIChat-price-text-color": "#252325",
    "--theme-AIChat-price-text-color-hover": "#FFE000",
    "--theme-AIChat-price-ul-text-align": "left",
    "--theme-AIChat-price-item-bg": "#F2F2F2",
    "--theme-AIChat-price-item-bg-hover": "#111212",
    "--theme-AIChat-price-item-border-radius": "15px",
    "--theme-AIChat-price-light": "#414141",
    "--theme-AIChat-price-deep": "#414141",
    // AIChat - question
    "--theme-AIChat-question-bg": "#F6F6F6",
    // AIChat - comment
    "--theme-AIChat-comment-border-radius": "15px",
    "--theme-AIChat-comment-item-bg-hover": "#FFE000",
   
   }