<template>
    <div class="outWrap">
      <div class="price-container" id="price">
        <h1 class="title">{{ content.title }}</h1>
        <p class="des">{{ content.des }}</p>
        <div class="price-list">
          <div class="price-setup" v-for="(item, idx) in content.priceSetup" :key="idx">
              <div class="tag">{{ item.tag }}</div>
              <div class="money"
                v-html="item.price.replace(/{money}/g, productList[idx].money).replace(/{useTime}/g, productList[idx].useTime)">
              </div>
            
            <ul>
              <li class="des" v-for="line in item.desList" :key="line">
                {{ line }}
              </li>
            </ul>
            <button :style="`cursor: ${loading ? 'wait' : ''}`" v-show="productList.length" class="pay-button"
              @click="handlePay(idx)">
              {{ item.buttonTxt }}
            </button>
            <p class="tip" v-html="item.tip
              .replace(/{money}/g, productList[idx].money)
              .replace(/{price}/g, productList[idx].price)
              .replace(/{useTime}/g, productList[idx].useTime)">
            </p>
          </div>
        </div>
      </div>
    </div>
    </template>
    
    <script>
    const currencyMap = {
      USD: '$',
      PHP: '₱',
      ZAR: 'R',
      GBP: '£',
      SGD: 'S$',
      NZD: 'NZ$',
      EUR: '€',
      INR: '₹',
      CAD: 'C$',
      AUD: 'AUD',
      IDR: 'Rp',
      MYR: 'RM',
      KRW: '₩',
      HKD: 'HK$',
      TWD: 'NT$',
      BRL: 'R$',
      THB: '฿',
    }
    
    const offerTypePrices = {
      0: 'price',
      1: 'tryoutPrice',
      2: 'firstDiscountPrice'
    }
    
    const durationUnitType = {
      1: 'hour',
      2: 'day',
      3: 'week',
      4: 'month',
      5: 'year',
      100: 'Lifetime',
    }
    
    //  填充价格套餐
    const useFillPriceInfo = (item) => {
      item.money = item[offerTypePrices[item.offerType]]
    
      let unit = 'durationUnit'
      let duration = 'duration'
      if (item.offerType === 1) {
        unit = 'tryoutDurationUnit'
        duration = 'tryoutDuration'
      }
      const durationUnit = item[unit]
      const durationDate = item[duration]
    
      item.useTime = durationUnitType[durationUnit]
      if (durationDate === 100) {
        item.useTime = 'LifeTime'
      } else {
        item.useTime = durationDate + '-' + item.useTime
      }
    
      item.currencyUnit = currencyMap[item.currency]
    
      return item
    }
    
    import { getProductList, createOrder, payOrder } from '@/api/pay'
    import { $extractDomain } from "@/utils/index"
    export default {
      name: 'AIChat_Price',
      props: {
        payUrl: {
          type: Object,
          required: true,
          default: () => { }
        },
        content: {
          type: Object,
          required: true,
          default: () => { }
        }
      },
      data() {
        return {
          loading: false,
          //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
          productList: [
            {
              money: '39.99',
              price: '39.99',
              useTime: '1-Month'
            },
            {
              money: '299.99',
              price: '299.99',
              useTime: '1-Year'
            },
          ]
        }
      },
      created() {
        const mainPart = $extractDomain(window.location.hostname)
        getProductList({ code: mainPart, extend: {} }).then(result => {
          if (result.result && result.result.length) {
            this.productList = result.result.map(useFillPriceInfo)
          }
        })
      },
      mounted() {
        if (this.$route.hash) {
          this.$nextTick(() => {
            document.querySelector(this.$route.hash) && document.querySelector(this.$route.hash).scrollIntoView()
          })
        }
      },
      methods: {
        async handlePay(idx) {
          if (!this.productList[idx].productsPriceList) {
            return this.nopay()
          }
          if (this.loading) return
          this.loading = true
          try {
            const order = await createOrder({
              productsId: this.productList[idx].id,
              productsPriceId: '0',
              successUrl: `${this.$origin(this.payUrl.successUrl || 'sub/payresult')}`, //  支付成功url
              cancelUrl: `${this.$origin(this.payUrl.cancelUrl || 'sub/payresult')}`, //  取消支付链接
              userExtend: {},
              transparentExtend: {},
              orderAdInfoExtend: {},
              marketingChannel: 'seo'
            })
    
            const result = await payOrder({
              orderId: order.obj.orderId,
              payExtend: {}
            })
            window.location.href = result.obj.redirectUrl
          } catch (e) {
            this.loading = false
            alert(e.msg || e)
          }
        },
        nopay() {
          alert('Please wait patiently for us to access the payment')
        }
      }
    }
    </script>
    
    <style scoped lang="scss">
  @media #{'only screen and (min-width: 992px)'} {
      .outWrap {
        .price-container {
          width: 1080px;
          margin: 0 auto 80px;
          // padding: 0 100px;
          padding-top: 50px;
          text-align: center;
          font-family: Arial, Arial;
          .title {
            margin-bottom: 24px;
            font-size: 60px;
            font-weight: 900;
            color: var(--theme-primary-black);
            text-align: center;
          }
      
          .des {
            font-size: 16px;
            font-weight: 400;
            color: var(--theme-primary-black);
            line-height: 24px;
            margin-bottom: 44px;
          }
      
          .price-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            text-align: left;
  
            .price-setup {
              margin: 0 auto;
              // padding: 32px;
              box-sizing: border-box;
              background: var(--theme-AIChat-price-item-bg);
              box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.15);
              border-radius: var(--theme-AIChat-price-item-border-radius);
              overflow: hidden;
              &:hover{
                background: var(--theme-AIChat-price-item-bg-hover);
                .tag {
                  background: var(--theme-AIChat-price-deep);
                  color: white;
                }
                ul{
                  li{
                    color: white;
                  }
                }
                 .money {
                    color: var(--theme-AIChat-price-text-color-hover);
                  }
                .tip {
                  background: var(--theme-AIChat-price-light);
                  color: white;
                }
                .pay-button {
                  background: var(--theme-AIChat-price-btn-bg-hover);
                  color: var(--theme-AIChat-price-btn-color-hover);
                }
              }
              .tag {
                padding: 40px 0;
                font-size: 30px;
                text-align: center;
                color: var(--theme-primary-black);
                border-bottom: 1px solid #E6E6E6;
                background: #E6E6E6;
              }
              .money {
                padding: 20px 0;
                font-weight: 900;
                font-size: 40px;
                color: var(--theme-AIChat-price-text-color);
                text-align: center;
              }
              ul {
                margin: 16px 0 24px;
                min-height: 300px;
                padding: 0 50px;
                list-style: none;
                text-align: var(--theme-AIChat-price-ul-text-align);
                li {
                  font-size: 20px;
                  color: var(--theme-primary-black);
                  margin-bottom: 15px;
  
                }
              }
      
              .pay-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 25px 0;
                width: 80%;
                font-size: 26px;
                border:none;
                font-weight: bold;
                background: var(--theme-AIChat-price-btn-bg);
                box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
                border-radius: 20px;
                color: var(--theme-AIChat-price-btn-color);
                margin: 0 auto 40px;
                line-height: 24px;
                cursor: pointer;
                // &:hover {
                //   background: #D13200;
                // }
              }
      
              .tip {
                margin-top: 10px;
                font-size: 16px;
                color: #7D7D80;
                padding: 25px 32px;
                background: #E6E6E6;
              }
            }
          }
        }
        }
      }
      
   @media #{'only screen and (max-width: 991px)'} {
        .outWrap {
          .price-container {
            width: 100%;
            margin: 1rem auto;
            text-align: center;
            padding: 0.32rem;
            box-sizing: border-box;
            font-family: Arial, Arial;
        
          .title {
            margin-bottom: 24px;
            font-size: 36px;
            color: var(--theme-primary-black);
            line-height: 42px;
            text-align: center;
          }
      
          .des {
            margin-bottom: 44px;
            font-size: 16px;
            font-weight: 400;
            color: var(--theme-primary-black);
            line-height: 24px;
          }
          .price-list {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            text-align: left;
      
            .price-setup {
              min-width: 100%;
              margin: 0 auto;
              // padding: 32px;
              background: var(--theme-AIChat-price-item-bg);
              box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
              border-radius: var(--theme-AIChat-price-item-border-radius);
              overflow: hidden;
              .tag {
                padding: .5rem 0;
                font-size: .4rem;
                text-align: center;
                color: var(--theme-primary-black);
                border-bottom: 1px solid #E6E6E6;
                background: #E6E6E6;
              }
              .money {
                padding: .5rem 0;
                font-weight: 900;
                font-size: .6rem;
                color: var(--theme-AIChat-price-text-color);
                text-align: center;
              }
      
              ul {
                margin: .5rem 0;
                padding: 0 .6rem;
                list-style: none;
                text-align: var(--theme-AIChat-price-ul-text-align);
                li {
                  font-size: .3rem;
                  margin-bottom: .2rem;
                  color: var(--theme-primary-black);
                }
              }
      
              .pay-button {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .4rem;
                width: 70%;
                margin-bottom: 1rem;
                color: var(--theme-AIChat-price-btn-color);
                padding: .3rem 0;
                font-weight: bold;
                background: var(--theme-AIChat-price-btn-bg);
                box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
                margin: 1rem auto .5rem;
                border-radius: .2rem;
                border: none;
                outline: none !important;
              }
      
              .tip {
                font-size: .3rem;
                color: #7D7D80;
                padding: .5rem;
                background: #E6E6E6;
              }
            }
          }
        }
      }
    
    }
    </style>
    