const Price = {
  // pdf价格
  "--theme-pdf-price-tag-bg": "#F2F4F3",
  "--theme-pdf-price-tag-bg-hover": "#F2F4F3",
  "--theme-pdf-price-tag-color": "#252325",
  "--theme-pdf-price-tag-color-hover": "#252325",

  "--theme-pdf-price-li-color": "#252325",
  "--theme-pdf-price-li-color-hover": "#252325",

  "--theme-pdf-price-tips-color": "#7D7D7F",
  "--theme-pdf-price-tips-color-hover": "#7D7D7F",
  "--theme-pdf-price-tips-bg": "#E2E2E5",
  "--theme-pdf-price-tips-bg-hover": "#E2E2E5",

  "--theme-pdf-price-color": "#FD2C52",
  "--theme-pdf-price-color-hover": "#FD2C52",

  "--theme-pdf-price-inner-bg": "#F2F4F3",
  "--theme-pdf-price-inner-bg-hover": "#F2F4F3",

  "--theme-pdf-price-btn": "#252325",
  "--theme-pdf-price-btn-hover": "#252325",

  "--theme-pdf-price-btn-color": "#fff",
  "--theme-pdf-price-btn-color-hover": "#fff",
}

const Questions = {
  // PDF
  // PDF quetions
  "--theme-pdf-questions-title-color": "#fff",
  "--theme-pdf-quesions-bg": "#FD2C52",
  "--theme-pdf-quesions-inner-bg": "#252325",

  "--theme-pdf-quesions-item-bg": "#252325",
  "--theme-pdf-quesions-item-title-color": "#fff",
  "--theme-pdf-quesions-item-title-color-hover": "#fff",

  "--theme-pdf-question-icon-filter": "brightness(100)",

  "--theme-pdf-quesions-item-des-color": "rgba(255,255,255,0.7)",
}

const Comment = {
  "--theme-pdf-comment-item-bg-hover": "#FD2C52",
}

const Steps = {
  "--theme-pdf-step-bg": "#F6F6F6",
  "--theme-pdf-step-inner-bg": "white"
}

const Explain = {
  "--theme-pdf-explain-inner-bg": "white",
}

export const PDFawesomeRed = {
  ...Price,
  ...Questions,
  ...Steps,
  ...Explain,
  ...Comment
}