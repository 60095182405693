export const AIChatLigthBlue = {
 // 模块配置
 // AIChat - banner
 "--theme-AIChat-banner-title-color": "white",
 "--theme-AIChat-banner-des-color": "white",
 "--theme-AIChat-banner-btn-color": "white",
 "--theme-AIChat-banner-btn-bg": "#28D1FB",
 // AIChat - explain
 "--theme-AIChat-explain-bg": "white",
 "--theme-AIChat-explain-inner-bg": "white",
 "--theme-AIChat-explain-item-shadow": "none",
 "--theme-AIChat-explain-inner-bg-hover": "#17A18A",
 // AIChat - step
 "--theme-AIChat-step-bg": "#101112",
 "--theme-AIChat-step-inner-bg": "white",
 "--theme-AIChat-step-inner-bg-hover": "#28D1FB",
 "--theme-AIChat-step-title-color": "white",
 "--theme-AIChat-step-inner-title-color": "#01002C",
 "--theme-AIChat-step-inner-desc-color": "#252325",
 "--theme-AIChat-step-inner-title-color-hover": "#252325",
 "--theme-AIChat-step-inner-desc-color-hover": "#252325",
 "--theme-AIChat-step-radius": "0px",
 // AIChat - price
 "--theme-AIChat-price-btn-bg": "#FF612F",
 "--theme-AIChat-price-btn-bg-hover": "#FF612F",
 "--theme-AIChat-price-btn-color": "#252325",
 "--theme-AIChat-price-btn-color-hover": "#252325",
 "--theme-AIChat-price-text-color": "#252325",
 "--theme-AIChat-price-text-color-hover": "#FF612F",
 "--theme-AIChat-price-ul-text-align": "left",
 "--theme-AIChat-price-item-bg": "#F2F2F2",
 "--theme-AIChat-price-item-bg-hover": "#101112",
 "--theme-AIChat-price-item-border-radius": "0px",
 "--theme-AIChat-price-light": "#101112",
 "--theme-AIChat-price-deep": "#222425",
 // AIChat - question
 "--theme-AIChat-question-bg": "#F6F6F6",
 // AIChat - comment
 "--theme-AIChat-comment-border-radius": "0px",
 "--theme-AIChat-comment-item-bg-hover": "#28D1FB",

}