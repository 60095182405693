<template>
    <div :class="['pdf_comment', content.right && 'right', content.top && 'top']">
        <div class="pdf_content">
            <p class="title">{{ content.title }}</p>
            <div class="area">
                <div v-for="(item, index) in content.list" :key="index" class="item">
                    <p class="subtitle">{{ item.subtitle }}</p>
                    <div class="wrap">
                        <img :src="$getFile(item.img)" v-if="item.img" alt="">
                        <div>
                            <div class="name">{{ item.name }}</div>
                            <div class="city">{{ item.city }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'PDF_Comment',
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { }
        }
    }
}
</script>
  
<style scoped lang="scss">
.pdf_comment {

    .pdf_content {
        .title {
            color: var(--theme-primary-black);
        }

        .item {
            background: var(--theme-pdf-comment-item-bg, #fff);

            .city {
                color: var(--theme-pdf-comment-city-color, #999199);
            }

            .name {
                color: var(--theme-pdf-comment-name-color, #4C484C);
            }

            .subtitle {
                color: var(--theme-pdf-comment-subtitle-color, --theme-primary-black);
            }

            &:hover {
                background: var(--theme-pdf-comment-item-bg-hover, --theme-primary);


                .subtitle {
                    color: var(--theme-pdf-comment-subtitle-color-hover, white);
                }

                .name {
                    color: var(--theme-pdf-comment-name-color-hover, white);
                }

                .city {
                    color: var(--theme-pdf-comment-city-color-hover, white);
                }
            }
        }
    }


    &.top {
        .item {
            display: flex;
            flex-direction: column-reverse !important;

            .subtitle {
                margin: 20px 0 0 !important;
            }
        }
    }

    &.right {
        .wrap {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }
}

@media #{'only screen and (min-width: 992px)'} {
    .pdf_comment {
        width: 100%;

        .pdf_content {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 1080px;
            margin: 0 auto;
            padding: 80px 0;
            font-family: Arial, Arial;

            .title {
                margin-bottom: 12px;
                font-size: 60px;
                font-weight: 900;
                line-height: 1.3;
                text-align: center;
                // width: 70%;
                margin: 0 auto 30px;
            }

            .area {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                width: 100%;
                margin-top: 42px;

                .item {
                    position: relative;
                    padding: 30px 20px;
                    box-shadow: 0px 2px 8px 0px rgba(92, 104, 130, 0.1);
                    border-radius: 10px;
                    overflow: hidden;
                    border: 2px solid #F2F4F3;
                    transition: all .3s;
                    overflow: visible;
                    display: flex;
                    flex-direction: column;

                    img {
                        display: block;
                        margin-right: 20px;
                    }

                    .wrap {
                        display: flex;
                        align-items: center;
                    }

                    .subtitle {
                        margin-bottom: 40px;
                        font-size: 20px;
                        line-height: 1.4;
                        min-height: 250px;
                    }

                    .name {
                        font-size: 20px;
                        margin-bottom: 8px;
                    }

                    .city {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media #{'only screen and (max-width: 991px)'} {
    .pdf_comment {
        width: 100%;

        .pdf_content {
            width: 100%;
            padding: .8rem 0;
            font-family: Arial, Arial;

            .title {
                margin: 0 auto .5rem;
                font-size: .48rem;
                text-align: center;
                font-weight: 900;
            }

            .area {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: .5rem;

                .item {
                    position: relative;
                    padding: .5rem .4rem;
                    border-radius: .2rem;
                    border: 1px solid #F2F4F3;
                    //   transition: all .3s;
                    overflow: visible;
                    margin: 0 .3rem;

                    img {
                        display: block;
                        margin-right: .3rem;
                    }

                    .wrap {
                        display: flex;
                        align-items: center;
                    }

                    .subtitle {
                        margin-bottom: .3rem;
                        line-height: 1.4;
                        font-size: .3rem;
                    }

                    .name {
                        margin-bottom: .1rem;
                        font-size: .4rem;
                        // line-height: 1.2;
                    }

                    .city {
                        font-size: .3rem;
                    }
                }
            }
        }
    }

}
</style>