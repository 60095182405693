<template>
    <div>
      <div class="maks" v-loading="loading" v-if="loading"></div>
      <div class="rich-text" v-if="info.contentStatus === 1">
        <div class="wangeditor">
          <Editor v-model="content" mode="default" id="editor" @onCreated="onCreated" />
        </div>
      </div>
  
      <div class="component-wrap" v-else>
        <div v-for="(component, name) in components" :key="name">
          <component :is="component.component" v-bind="component.props"></component>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { Editor } from '@wangeditor/editor-for-vue'
  export default {
    name: "payout",
    components: { Editor },
    data() {
      return {
        info: {},
        components: {},
        content: "",
        loading: true
      };
    },
    mounted() {
      this.init()
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        this.editor.disable();
      },
      async init() {
        const $id = this.$route.params.id;
        let host = this.$extractDomain(window.location.hostname)
        if (host == 'localhost') host = sessionStorage.getItem('SiteEntrance')
        let result = await this.$api.getPageInfo({ host, page: $id })
        this.info = result.data
        if (this.info.contentStatus === 1) { // 富文本
          this.content = this.info.richText
        } else {
          for (const [name, config] of Object.entries(this.info.content)) {
            this.$set(this.components, name, { component: name, props: config.props });
          }
        }
        this.$nextTick(() => {
          this.loading = false
        })
      },
    },
  };
  </script>
  <style scoped lang="scss">
  @media #{'only screen and (min-width: 992px)'} {
  
    .rich-text {
      max-width: 1080px;
      margin: 0 auto;
      padding: 60px;
      padding-bottom: 200px;
  
      ::v-deep strong {
        font-family: Poppins !important;
        color: #05021e;
      }
  
      h1 {
        font-size: 48px;
        font-family: Poppins;
        color: #05021e;
        line-height: 72px;
        text-align: center;
        margin-bottom: 50px;
      }
  
      .rich-des {
        font-size: 18px;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #05021e;
        line-height: 28px;
        margin-bottom: 0.6rem;
      }
  
      .a-p {
        margin-top: 20px;
  
        ::v-deep a {
          margin-top: 20px;
          color: #0000ee;
          text-decoration: underline;
  
          &:hover {
            opacity: 0.8;
          }
        }
      }
  
      h2 {
        font-size: 28px;
        font-family: Poppins;
        color: #05021e;
        line-height: 36px;
        margin-top: 80px;
        margin-bottom: 20px;
      }
  
      .rich-item-des {
        font-size: 16px;
        line-height: 24px;
        margin-top: 30px;
      }
  
      .rich-child-des {
        font-size: 16px;
        line-height: 24px;
        margin-top: 30px;
      }
  
      ul {
        padding-left: 40px;
  
        li {
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
          color: #05021e;
          list-style-type: disc;
        }
  
        .rich-li-text {
          font-size: 16px;
          line-height: 24px;
          margin-top: 15px;
          color: #05021e;
        }
  
        .table {
          border: none;
          margin-top: 30px;
          margin-bottom: 60px;
  
          ::v-deep table {
            width: 100%;
            border: 1px solid #05021e;
  
            td {
              font-size: 16px;
              font-family: Poppins-Regular, Poppins;
              color: #05021e;
              line-height: 24px;
              padding: 10px;
              border: 1px solid #05021e;
            }
          }
        }
      }
    }
  
    .component-wrap {
      padding: 0px;
    }
  }
  
  @media #{'only screen and (max-width: 991px)'} {
    .rich-text {
      margin: 0.5rem auto 0;
      padding: 0 0.32rem 0.58rem;
      box-sizing: border-box;
  
      ::v-deep strong {
        font-family: Poppins !important;
        color: #05021e;
      }
  
      .guide-mobile-header {
        margin-bottom: 0.5rem;
  
        .bread-nav-box {
          padding: 0.2rem 0;
  
          .icon-home {
            width: 0.4rem;
            height: 0.4rem;
          }
  
          .arrow-right {
            width: 0.15rem;
            height: 0.22rem;
            margin-left: 0.23rem;
          }
  
          .bread-title {
            margin-left: 0.23rem;
            color: #05021e;
            font-size: 0.24rem;
            line-height: 0.36rem;
            font-family: Poppins-Medium, Poppins;
            font-weight: 500;
          }
        }
      }
  
      h1 {
        font-size: 0.4rem;
        font-family: Poppins;
        color: #05021e;
        line-height: 0.64rem;
        text-align: left;
        margin-bottom: 0.4rem;
      }
  
      .rich-des {
        font-size: 0.32rem;
        font-family: Poppins-Regular, Poppins;
        font-weight: 400;
        color: #05021e;
        line-height: 0.48rem;
        margin-bottom: .6rem;
      }
  
      .a-p {
        margin-top: 20px;
  
        ::v-deep a {
          margin-top: 20px;
          color: #0000ee;
          text-decoration: underline;
  
          &:hover {
            opacity: 0.8;
          }
        }
      }
  
      h2 {
        font-size: 0.4rem;
        font-family: Poppins;
        color: #05021e;
        line-height: 0.64rem;
        margin-bottom: 0.4rem;
        text-align: left;
      }
  
      .rich-item {
        margin-bottom: 0.6rem;
      }
  
      .rich-item-des {
        font-size: 0.32rem;
        line-height: 0.48rem;
        margin-top: 0.4rem;
      }
  
      .rich-child-des {
        font-size: 0.32rem;
        line-height: 0.48rem;
        margin-top: 0.24rem;
      }
  
      ul {
        padding-left: 0.3rem;
  
        li {
          font-size: 0.32rem;
          line-height: 0.48rem;
          margin-top: 0.32rem;
          color: #05021e;
          list-style-type: decimal;
  
          &:last-child {
            margin-left: -0.3rem;
            list-style-type: none;
          }
        }
  
        .rich-li-text {
          font-size: 16px;
          line-height: 24px;
          margin-top: 15px;
          color: #05021e;
          display: flex;
          align-items: flex-start;
  
          .dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            flex-shrink: 0;
            background: #05021e;
            border-radius: 50%;
            margin-top: 8px;
            margin-right: 5px;
          }
        }
  
        .table {
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
          width: 100%;
          overflow: auto;
          border: 1px solid #05021e;
  
          ::v-deep table {
            td {
              font-size: 16px;
              font-family: Poppins-Regular, Poppins;
              color: #05021e;
              line-height: 24px;
              padding: 10px;
              border-right: 1px solid #05021e;
  
              &:last-child {
                border-right: none !important;
              }
            }
          }
        }
      }
    }
  }
  </style>
  
  <style src="@wangeditor/editor/dist/css/style.css"></style>